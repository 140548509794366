import { styled } from '@stitches/react';
import React from 'react';
import { Document, Page } from 'react-pdf';

export const StyledDocument = styled(Document, {
  width: '100%',
  height: '100%',
  margin: 'auto',
});

export const StyledPage = styled(Page, {
  margin: '0 auto',
  width: '100% !important',
  height: 'auto !important',
  '&:active': {
    cursor: 'grab',
  },
});

const Loading = styled('div', {
  width: '651px',
  height: '725px',
  border: '1px solid var(--greys-2, #DEE6EB)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const StyledPlaceholder = () => (
  <Loading>
    <p>Loading file...</p>
  </Loading>
);
