import { Description, Portal, Root, Title } from '@radix-ui/react-dialog';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import type * as Stitches from '@stitches/react';
import useOnClickOutside from 'hooks/useOnOutsideClick';
import React, { useRef } from 'react';

import ModalContent from './ModalContent';
import ModalOverlay from './ModalOverlay';

/*
ref: https://www.radix-ui.com/docs/primitives/components/dialog#root
*/
interface IProps {
  children: React.ReactNode;
  accessibilityTitle: string;
  accessibilityDescription: string;
  open: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  className?: string | null;
  preventAutoFocus?: boolean;
  css?: Stitches.CSS;
}
export const Modal = ({
  open,
  onOpen,
  onClose,
  children,
  accessibilityTitle,
  accessibilityDescription,
  preventAutoFocus,
  className,
  css,
}: IProps) => {
  const ref = useRef<HTMLDivElement>();
  useOnClickOutside(ref, () => onClose());

  return (
    <Root open={open} onOpenChange={onOpen} modal={true}>
      <Portal>
        <ModalOverlay ref={ref}>
          <ModalContent
            css={css}
            className={className}
            onEscapeKeyDown={onClose}
            onPointerDownOutside={onClose}
            preventAutoFocus={preventAutoFocus}
            ref={ref}
          >
            <VisuallyHidden.Root asChild={true}>
              <Title>{accessibilityTitle}</Title>
            </VisuallyHidden.Root>

            <VisuallyHidden.Root asChild={true}>
              <Description>{accessibilityDescription}</Description>
            </VisuallyHidden.Root>

            {children}
          </ModalContent>
        </ModalOverlay>
      </Portal>
    </Root>
  );
};
