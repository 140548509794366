import cx from 'classnames';
import type { ComponentPropsWithoutRef } from 'react';
import React from 'react';

import styles from './Checkbox.module.scss';

interface IProps extends ComponentPropsWithoutRef<'input'> {
  label?: string | undefined;
  className?: string | undefined;
  disabled?: boolean | undefined;
  labelClassName?: string | undefined;
  note?: string | undefined;
}

const CheckboxWithRef = ({ label, className, labelClassName, id, disabled, note, ...rest }: IProps, ref) => {
  return (
    <>
      <label className={cx(styles.label, labelClassName, { [styles.disabled]: disabled })} htmlFor={id}>
        <input
          id={id}
          disabled={disabled}
          type="checkbox"
          className={cx(styles.checkbox, className)}
          value={rest.value || ''}
          checked={!!rest.value}
          ref={ref}
          {...rest}
        />
        {label}
      </label>
      {note && <div className={styles.note}>{note}</div>}
    </>
  );
};

const Checkbox = React.forwardRef<HTMLInputElement, IProps>(CheckboxWithRef);
export default Checkbox;
