import { Trigger } from '@radix-ui/react-dialog';
import React from 'react';

/*
ModalTrigger is an optional component to use as a wrapper around the element that would
open the Modal. For our purposes, it's much more common for the Modal's opening logic
to be controlled by a parent component (ie, the PayCalcCalendar controls the opening of the
PayCalcItemModal). ModalTrigger allows you to create a Modal component that does not rely on a Parent to
maintain its Open/Closed state.
Ref: https://www.radix-ui.com/docs/primitives/components/dialog#trigger

export default YourModal = () => (
  <Modal>
    <Dialog.Trigger>
      <Button>Open Modal </Button>
    </Dialog.Trigger>
    <h2>Your Content</h2>
      <div>...</div>
      <div>...</div>
      <div>...</div>
  </Modal>
);

*/
interface Props {
  children: React.ReactNode;
  renderAsChildElement?: boolean;
}
export const ModalTrigger = ({ children, renderAsChildElement = true }: Props) => {
  return <Trigger asChild={renderAsChildElement}>{children}</Trigger>;
};
