import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import * as sanitizeHtml from 'sanitize-html';
import { cancelPromptAction, okPromptAction } from 'store/prompt';

import { Button, ButtonColor } from '@components/v2';
import FormError from '../Forms/Error/FormError';
import Modal from '../Modal';
import styles from './Prompt.module.scss';
import { useAppSelector, useAppDispatch } from 'hooks/reduxHooks';

const Prompt = () => {
  const prompt = useAppSelector((state) => state.prompt);
  const [open, setOpen] = useState(false);
  const { message, okButton, cancelButton, title, loading, error } = (prompt && prompt.info) || {};

  useEffect(() => {
    if (prompt && prompt.info) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [prompt]);

  const dispatch = useAppDispatch();

  let promptRoot = document.getElementById('prompt-portal-root');
  if (!promptRoot) {
    promptRoot = document.createElement('div');
    promptRoot.setAttribute('id', 'prompt-portal-root');
    document.body.parentElement.insertBefore(promptRoot, document.body);
  }

  if (!open) return null;

  return ReactDOM.createPortal(
    <Modal
      open={open}
      onClose={() => dispatch(cancelPromptAction())}
      className={styles.container}
      wrapClassName={styles.wrap}
    >
      <div className={styles.content}>
        {title && <h3>{title}</h3>}
        <FormError error={error} />
        <p
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(message),
          }}
        />
      </div>
      <div className={styles.actionWrap}>
        <Button
          onClick={() => dispatch(cancelPromptAction())}
          type="button"
          className={styles.action}
          color={ButtonColor.OUTLINE}
        >
          {cancelButton || 'Cancel'}
        </Button>
        <Button
          loading={loading}
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(okPromptAction());
          }}
          className={styles.action}
        >
          {okButton}
        </Button>
      </div>
    </Modal>,
    promptRoot,
  );
};

export default Prompt;
