// this is an awful pattern from an external library OTL was using.
// we'll start replacing it's uses with time. Copying all the library here so we can remove the dependency
export default function promiseCreator() {
  let resolve;
  let reject;
  const promise = new Promise(function (res, rej) {
    resolve = res;
    reject = rej;
  });
  return {
    resolve: resolve,
    reject: reject,
    promise: promise,
  };
}
