import { Trigger } from '@radix-ui/react-popover';
import React from 'react';

interface IProps extends React.ComponentPropsWithoutRef<typeof Trigger> {
  children?: React.ReactChild | React.ReactChild[];
}

/**
 * https://www.radix-ui.com/primitives/docs/components/popover#trigger
 */
export const PopoverTrigger = ({ children, ...rest }: IProps) => {
  return (
    <Trigger data-testid="popover-trigger" {...rest}>
      {children}
    </Trigger>
  );
};
