import * as DialogPrimitive from '@radix-ui/react-dialog';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import cx from 'classnames';
import useOnClickOutside from 'hooks/useOnOutsideClick';
import React, { useRef } from 'react';

import styles from './Dialog.module.scss';

interface IProps {
  children: React.ComponentProps<any>;
  open: boolean;
  onClose: () => void;
  className?: string | null;
  title?: string | null;
  description?: string | null;
}

const Dialog = ({ children, onClose, open, className, title, description }: IProps) => {
  const ref = useRef();
  useOnClickOutside(ref, () => onClose());
  return (
    <DialogPrimitive.Root open={open}>
      <DialogPrimitive.Overlay className={styles.overlay} />
      <DialogPrimitive.Content ref={ref} className={cx(styles.content, className)}>
        {title && (
          <VisuallyHidden.Root>
            <DialogPrimitive.Title>{title}</DialogPrimitive.Title>{' '}
          </VisuallyHidden.Root>
        )}
        {description && (
          <VisuallyHidden.Root>
            <DialogPrimitive.Description>{description}</DialogPrimitive.Description>
          </VisuallyHidden.Root>
        )}

        {children}
      </DialogPrimitive.Content>
    </DialogPrimitive.Root>
  );
};

export default Dialog;
