import { Item } from '@radix-ui/react-accordion';
import React from 'react';

/**
 * https://www.radix-ui.com/docs/primitives/components/accordion#item
 */
interface IProps {
  className?: string;
  value: string;
  children?: React.ReactChild | React.ReactChild[];
}

export const AccordionItem = ({ className, children, value }: IProps) => {
  return (
    <Item value={value} className={className} data-testid="accordion-item">
      {children}
    </Item>
  );
};
