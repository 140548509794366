import { IScheduleDaysVM } from '@types';
import { SCHEDULE_DAY_TYPES } from '@constants/index';
import { ITimeOffBalance } from '@constants/types';
import { differenceInDays, formatDuration, getDay, parseISO, isAfter } from 'date-fns';
import STEP_CHAPTERS from '@constants/step-chapters';

type ScheduleRange = {
  from: string;
  to: string;
  minutesPerDayMask: number[];
};

export const isReservedSubdomain = () => {
  const subdomain = window.location.host.split('.')[0];
  return subdomain === 'admin' || subdomain === 'secure' || subdomain === 'login' || subdomain === 'dev-admin';
};

export const calculateTotalMinutesFromRange = (range: IScheduleDaysVM | ScheduleRange): number => {
  const length = differenceInDays(parseISO(range.to), parseISO(range.from)) + 1;
  return calculateTotalMinutesFromMask(parseISO(range.from), length, range.minutesPerDayMask);
};

export const calculateHoursAndMinutes = (totalMinutes) => {
  let hours = 0;
  let minutes = 0;
  if (!totalMinutes) {
    return { hours, minutes };
  }
  hours = Math.trunc(totalMinutes / 60);
  minutes = totalMinutes % 60;
  return { hours, minutes };
};

export const calculateTotalMinutesFromMask = (from, length, minutesPerDayMask): number => {
  const fullWeeks = Math.floor(length / 7);
  const fullWeekMinutes = minutesPerDayMask.reduce((prev, curr) => prev + curr, 0);
  let totalMinutes = fullWeeks * fullWeekMinutes;
  const fromDay = getDay(from); // Sunday=0, Sat=6
  const nonFullWeekDays = length % 7;
  for (let i = 0; i < nonFullWeekDays; i++) {
    totalMinutes += minutesPerDayMask[(fromDay + i) % 7];
  }
  return totalMinutes;
};

export const highlightDaysFromWorkingSchedule = (data, date, plan) => {
  let toUse = null;
  let arr = [];
  const workingSchedules = data.filter((item) => item.type === SCHEDULE_DAY_TYPES.WORK_REGULAR);
  if (!workingSchedules.length) toUse = null;
  else if (workingSchedules.length === 1) toUse = workingSchedules[0];
  else if (workingSchedules.length > 1) {
    const sorted = workingSchedules.sort((a, b) => new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf());
    for (const schedule of sorted) {
      if (date >= parseISO(schedule.from) && date <= parseISO(schedule.to)) {
        toUse = schedule;
        break;
      }
    }
  } else return false;
  if (toUse) {
    arr = toUse?.minutesPerDayMask || [0, 480, 480, 480, 480, 480, 0];
  } else {
    arr = plan?.leave?.workingDays || [0, 480, 480, 480, 480, 480, 0];
  }
  if (arr.length) {
    const idx = getDay(date);
    return arr[idx] > 0;
  }
  return false;
};

export const formatBalance = (type: string, balances?: ITimeOffBalance[]) => {
  const balanceItem = balances?.find((item) => item.balanceType === type);
  if (!balanceItem) return 'Not available';
  if (balanceItem.isUnlimited) {
    return 'Unlimited';
  } else {
    const { hours, minutes } = calculateHoursAndMinutes(balanceItem.balance);
    return formatDuration({ hours, minutes }, { zero: true });
  }
};

export const raise = (err: unknown): never => {
  throw typeof err === 'string' ? new Error(err) : err;
};

export const getCurrentChapter = ({ dueDate, returnDate }) => {
  const now = new Date();
  let chapter = STEP_CHAPTERS.LEAVE;
  if (isAfter(now, parseISO(returnDate))) {
    chapter = STEP_CHAPTERS.RETURN;
  } else if (isAfter(parseISO(dueDate), now)) {
    chapter = STEP_CHAPTERS.PRE_LEAVE;
  }
  return {
    chapter,
  };
};
