import React from 'react';

import { MainZoomWrapper, ZoomIcon, ZoomIconWrapper, ZoomWrapper } from './components';
type ControlsProps = {
  onZoomIn: () => void;
  onZoomOut: () => void;
  onReset?: () => void;
  onRotateClockwise: () => void;
};

export const ZoomAndRotateControls = ({ onZoomIn, onZoomOut, onRotateClockwise, onReset }: ControlsProps) => {
  return (
    <MainZoomWrapper>
      <ZoomIconWrapper>
        <ZoomIcon name="RotateCw" onIconClick={onRotateClockwise} aria-label="rotate" title="Rotate" role="button" />
      </ZoomIconWrapper>
      <ZoomWrapper>
        <ZoomIconWrapper>
          <ZoomIcon name="Plus" onIconClick={onZoomIn} aria-label="zoom in" title="Zoom in" role="button" />
        </ZoomIconWrapper>
        <ZoomIconWrapper>
          <ZoomIcon name="Minus" onIconClick={onZoomOut} aria-label="zoom out" title="Zoom Out" role="button" />
        </ZoomIconWrapper>
      </ZoomWrapper>
      <ZoomIconWrapper>
        <ZoomIcon name="RefreshCcw" onIconClick={onReset} aria-label="reset" title="Reset" role="button" />
      </ZoomIconWrapper>
    </MainZoomWrapper>
  );
};
