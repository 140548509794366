import React from 'react';
import { pdfjs } from 'react-pdf';

import { StyledDocument, StyledPage, StyledPlaceholder } from './components';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IProps {
  setNumPages: (numPages: number) => void;
  pageNumber: number;
  url: string;
  rotation: number;
  onLoad: (isLoaded: boolean) => void;
}
export const PdfRenderer = ({ setNumPages, url, pageNumber, rotation, onLoad }: IProps) => {
  return (
    <StyledDocument
      file={url}
      onLoadSuccess={({ numPages }) => {
        onLoad(true);
        setNumPages(numPages);
      }}
      loading={StyledPlaceholder}
    >
      <div style={{ transform: `rotate(${rotation}deg)` }} data-testid="rotation-div">
        <StyledPage renderTextLayer={false} renderAnnotationLayer={false} pageNumber={pageNumber} width={612} />
      </div>
    </StyledDocument>
  );
};
