import { Button } from '@components/v2';
import { ButtonColor } from '@components/v2/Button/Button';
import React from 'react';
import { styled } from 'stitches.config';

const ButtonRow = styled('div', {
  display: 'flex',
  gap: 16,
  backgroundColor: '$grey100',
  padding: 24,
  '&.flex-end': {
    justifyContent: 'flex-end',
  },
  '&.space-between': {
    justifyContent: 'space-between',
  },
  '&.sticky': {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
});

export enum BUTTON_ALIGNMENT {
  FLEX_END = 'flex-end',
  SPACE_BETWEEN = 'space-between',
}

type Props = {
  submitLabel?: string;
  closeLabel?: string;
  buttonColor?: ButtonColor;
  onClose?: () => void;
  icon?: React.ReactNode;
  buttonType?: 'button' | 'submit' | 'reset';
  onSubmit?: () => void;
  isSubmitting?: boolean;
  disabled?: boolean;
  closeOnly?: boolean;
  cancelId?: string;
  dataTestId?: string;
  form?: string;
  buttonAlignment?: BUTTON_ALIGNMENT;
  sticky?: boolean;
  submitId?: string;
};

export const BaseButtonRow = ({
  onClose,
  disabled,
  submitLabel = 'Save',
  submitId,
  closeLabel = 'Close',
  cancelId,
  buttonColor = ButtonColor.PEACOCK,
  onSubmit,
  buttonType = 'submit',
  isSubmitting = false,
  closeOnly = false,
  icon = null,
  dataTestId,
  form,
  buttonAlignment = BUTTON_ALIGNMENT.FLEX_END,
  sticky = false,
}: Props) => {
  return (
    <ButtonRow className={`${buttonAlignment} ${sticky ? 'sticky' : ''}`}>
      <Button color={ButtonColor.OUTLINE} onClick={onClose} id={cancelId}>
        {closeLabel}
      </Button>
      {!closeOnly && (
        <Button
          icon={!!icon}
          color={buttonColor}
          onClick={onSubmit}
          disabled={disabled}
          loading={isSubmitting}
          type={buttonType}
          data-testid={dataTestId}
          form={form}
          id={submitId}
        >
          {icon}
          <span>{submitLabel}</span>
        </Button>
      )}
    </ButtonRow>
  );
};
