import actionKey from '@utils/action-key';
import request from '@utils/request';
import PromiseCreator from 'lib/promise-creator';
import { combineReducers } from 'redux';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { closePromptAction, openPromptAction, showPromptErrorAction } from '../prompt';
import {
  DELETE_FILE,
  FETCH_FILES,
  FETCH_PLAN_FILES,
  IMPORT_EMPLOYEES,
  TOGGLE_UPLOAD_FILE_MODAL,
  UPLOAD_FILE,
} from './constants';

const byId = (state = null, action) => {
  switch (action.type) {
    case actionKey.success(FETCH_FILES):
      return {
        ...action.payload.results.reduce((acc, file) => {
          acc[file.id] = {
            ...file,
          };
          return acc;
        }, {}),
      };
    case actionKey.success(FETCH_PLAN_FILES):
      return {
        ...action.payload.reduce((acc, file) => {
          acc[file.id] = {
            ...file,
          };
          return acc;
        }, {}),
      };
    case actionKey.success(UPLOAD_FILE):
      return {
        ...state,
        ...action.payload.reduce((acc, file) => {
          acc[file.id] = {
            ...file,
            uploader: action.me,
          };
          return acc;
        }, {}),
      };
    case actionKey.success(DELETE_FILE): {
      const files = {
        ...state,
      };
      delete files[action.id];
      return {
        ...files,
      };
    }
    default:
      return state;
  }
};

const uploadFileModal = (state = null, action) => {
  switch (action.type) {
    case actionKey.success(UPLOAD_FILE):
      return null;
    case TOGGLE_UPLOAD_FILE_MODAL:
      return action.payload;
    default:
      return state;
  }
};

const deleteLoading = (state = false, action) => {
  switch (action.type) {
    case DELETE_FILE:
      return true;
    case actionKey.failure(DELETE_FILE):
    case actionKey.success(DELETE_FILE):
      return false;
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case FETCH_FILES:
    case actionKey.success(FETCH_FILES):
      return null;
    case actionKey.failure(FETCH_FILES):
      return action.error;
    default:
      return state;
  }
};

export default combineReducers({
  byId,
  deleteLoading,
  error,
  uploadFileModal,
});

/**
 * SAGA LISTENERS
 */

export function* rootSaga() {
  yield all([
    takeEvery(FETCH_FILES, fetchFiles),
    takeEvery(FETCH_PLAN_FILES, fetchPlanFiles),
    takeEvery(UPLOAD_FILE, uploadFile),
    takeEvery(DELETE_FILE, deleteFile),
    takeEvery(IMPORT_EMPLOYEES, importEmployees),
  ]);
}

/**
 * SAGA ACTIONS
 */

export function* fetchPlanFiles({ type, resolve, reject, planId }) {
  // URLs with {planId} is superUser protected only
  const url = `/api/admin/plans/${planId}/files/`;

  yield call(request, {
    type,
    url,
    options: {
      apiV3: true,
    },
    resolve,
    reject,
  });
}

export function* fetchFiles({ type, resolve, reject }) {
  const url = '/api/legacy/files/';

  yield call(request, {
    type,
    url,
    options: {
      apiV3: true,
    },
    resolve,
    reject,
  });
}

export function* importEmployees({ type, payload, me, resolve, reject }) {
  const url = me.superuser
    ? `/api/admin/companies/${payload.companyId}/import-employees/`
    : '/api/hr/company/import-employees/';
  yield call(request, {
    type,
    url,
    options: {
      apiV3: true,
      method: 'POST',
      body: payload,
    },
    resolve,
    reject,
    me,
  });
}

export function* uploadFile({ type, payload, me, resolve, reject }) {
  if ('planOrLeaveId' in payload) {
    const planOrLeave = payload['planOrLeaveId'].split(':');
    payload[planOrLeave[0]] = planOrLeave[1];
  }
  yield call(request, {
    type,
    url: '/api/legacy/files/',
    options: {
      apiV3: true,
      method: 'POST',
      body: payload,
    },
    resolve,
    reject,
    me,
  });
}

export function* deleteFile({ type, payload, isSuperUser, resolve, reject }) {
  const prompt = PromiseCreator();
  yield put(
    openPromptAction({
      resolve: prompt.resolve,
      title: 'Delete File',
      message: `Are you sure that you want to delete "${payload.name}" file?`,
      okButton: 'Delete',
      showLoading: true,
    }),
  );

  const upgrade = yield prompt.promise;
  if (upgrade) {
    const url = isSuperUser ? `/api/admin/files/${payload.id}/` : `/api/legacy/files/${payload.id}/`;

    const result = yield call(request, {
      type,
      url: url,
      options: {
        apiV3: true,
        method: 'DELETE',
        body: payload,
      },
      resolve,
      reject,
      id: payload.id,
      stepId: payload.stepId,
      planId: payload.planId,
    });
    if (result.error) {
      yield put(showPromptErrorAction(result.error));
      yield deleteFile({
        type,
        payload,
        resolve,
        reject,
      });
    }
  }
  yield put(closePromptAction());
  if (payload.afterDelete) payload.afterDelete();
}
