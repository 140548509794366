import FeatherIcon from '@components/FeatherIcon';
import { styled } from 'stitches.config';

// zoom components:
export const MainZoomWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: 'fit-content',
  gap: '0.5rem',
  minHeight: '5.3rem',
  justifyContent: 'flex-end',
});

export const ZoomWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '0',
  justifyContent: 'space-around',
});

export const ZoomIcon = styled(FeatherIcon, {
  color: '$black',
  borderRadius: '4px',
  background: 'var(--greys-1, #F8F9FC)',
  width: '1rem',
  height: '1rem',
});
export const ZoomIconWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow:
    '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 2px 1px 0px rgba(0, 0, 0, 0.05), 0px 1px 1px 0px rgba(0, 0, 0, 0.05)',
  borderRadius: '4px',
  border: '1px solid var(--greys-2, #DEE6EB)',
  background: 'var(--greys-1, #F8F9FC)',
  cursor: 'pointer',
  width: '1.5rem',
  height: '1.5rem',
});
