import cx from 'classnames';
import React, { Fragment } from 'react';

import RadioButton from './RadioButton';
import styles from './RadioButton.module.scss';

export interface IRadioButtonOption {
  id: string;
  name: string;
  value: string | number;
  label: string;
  disabled?: boolean;
}

export enum EOrientation {
  ROW = 'row',
  COLUMN = 'column',
}

export enum EDisplayAs {
  REC_BUTTON = 'recButton',
}

interface IProps {
  label?: string;
  className?: string;
  labelClassName?: string;
  checked?: boolean;
  id?: string;
  name?: string;
  disabled?: boolean;
  value?: string | number;
  options: IRadioButtonOption[];
  onValueChange?: (value) => void;
  orientation?: EOrientation | string;
  context?: {
    key: number;
    context: string;
  }[];
  contextClassName?: string;
  error?: string | null;
  displayAs?: EDisplayAs;
}

const RadioButtonsWithRef = (
  {
    id,
    label,
    onValueChange,
    labelClassName,
    name,
    value,
    options,
    error,
    orientation = EOrientation.COLUMN,
    context = undefined,
    displayAs,
    className,
    contextClassName = '',
    ...rest
  }: IProps,
  ref,
) => {
  return (
    <>
      <label className={cx(styles.label, labelClassName)} htmlFor={id}>
        {label}
      </label>
      <div
        className={cx(className, {
          [styles.radioButtonsColumn]: orientation === EOrientation.COLUMN,
          [styles.radioButtonsRow]: orientation === EOrientation.ROW,
        })}
        role="radiogroup"
      >
        {options?.map((option, index) => {
          return (
            <Fragment key={`${option}_${index}`}>
              <RadioButton
                {...rest}
                key={option.id}
                label={option.label}
                checked={option.value === value}
                disabled={option.disabled || rest.disabled}
                id={option.id}
                name={name}
                value={option.value}
                onValueChange={onValueChange}
                ref={ref}
                displayAs={displayAs}
              />
              {context ? (
                <div className={contextClassName}>{context.find((obj) => obj.key === option.value).context}</div>
              ) : null}
            </Fragment>
          );
        })}
      </div>
      {error && <div className={styles.error}>{error}</div>}
    </>
  );
};

const RadioButtons = React.forwardRef<HTMLInputElement, IProps>(RadioButtonsWithRef);
export default RadioButtons;
