import { Item } from '@radix-ui/react-dropdown-menu';
import type * as Stitches from '@stitches/react';
import React from 'react';
import { styled } from 'stitches.config';

interface IProps {
  children?: React.ReactChild | React.ReactChild[];
  onSelect?: (event: Event) => void;
  disabled?: boolean;
  asChild?: boolean;
  css?: Stitches.CSS;
}
const StyledItem = styled(Item, {
  lineHeight: '$24',
  color: '$black',
  display: 'flex',
  alignItems: 'center',
  padding: '$8 $24',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: '$grey100',
    color: '$primaryPeacock',
  },
  '&:focus': {
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
  },
  '&[data-highlighted]': {
    border: 'none',
    outline: 'none',
    color: '$primaryPeacock',
  },
  // TODO SHARE-708: add disabled styling
  '& > a': {
    color: 'inherit',
  },
});
/**
 * https://www.radix-ui.com/docs/primitives/components/dropdown-menu#item
 */
export const DropdownMenuItem = ({ children, asChild = false, ...rest }: IProps) => {
  return (
    <StyledItem data-testid="dropdown-menu-item" asChild={asChild} {...rest}>
      {children}
    </StyledItem>
  );
};
