import FeatherIcon from '@components/FeatherIcon';
import {
  Arrow,
  IconContainer,
  Input,
  InputContainer,
  ListItem,
  MainContainer,
  Menu,
} from '@components/v2/StyleGuide/ColorGuide/components/DropdownStyles';
import { Text } from '@components/v2/StyleGuide/ColorGuide/components/Text';
import type * as Stitches from '@stitches/react';
import { useCombobox } from 'downshift';
import React, { useEffect, useState } from 'react';

type Item = { value: string | number; label: string };
type IProps = {
  items: Item[];
  onChange?: (val: string | number) => void;
  onInputChange: (val: string) => void;
  error?: string;
  css?: Stitches.CSS;
  placeholder?: string;
  id: string;
  label?: string;
  defaultValue?: Item;
  disabled?: boolean;
};

const Combobox = ({
  items,
  onChange,
  onInputChange,
  error,
  css,
  id,
  placeholder,
  label = '',
  defaultValue = null,
  disabled = false,
}: IProps) => {
  const [inputItems, setInputItems] = useState(items);
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    getLabelProps,
    openMenu,
    closeMenu,
    selectItem,
    inputValue,
    setInputValue,
  } = useCombobox({
    id,
    items: inputItems,
    itemToString: (item) => (item ? item.label : ''),
    onInputValueChange: ({ inputValue }) => {
      onInputChange(inputValue);
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        onChange(selectedItem.value);
      }
    },
    initialSelectedItem: defaultValue,
  });

  useEffect(() => {
    // This is because we debounce and pull the items from a parent component
    if (inputValue) {
      setInputItems(items);
    } else {
      setInputItems([]);
    }
  }, [items, setInputValue, inputValue]);

  const clearInput = () => {
    setInputValue('');
    selectItem(null);
    setInputItems([]);
    onChange(null);
  };

  return (
    <>
      <MainContainer css={css}>
        {label && (
          <Text TextType="inputLabel" as="label" {...getLabelProps()}>
            {label}
          </Text>
        )}

        <InputContainer as="div" disabled={disabled} label={!!label} error={!!error} css={css}>
          <Input
            {...getInputProps({
              disabled,
              'aria-label': 'Type to search or click to open the dropdown',
              onBlur: (e) => e.preventDefault(),
              onClick: () => (isOpen ? closeMenu() : openMenu()),
            })}
            data-testid={`combobox-test-${id}`}
            placeholder={placeholder}
          />
          {!disabled && (
            <IconContainer>
              {inputValue !== '' && (
                <FeatherIcon onClick={clearInput} name="X" height={16} width={16} className="clear-icon" />
              )}
              <Arrow
                {...getToggleButtonProps({
                  // Arrow is a styled <button> and when used within a <form> the
                  // click event propagates to the form onSubmit action so we must
                  // define a type of button to avoid submitting the form
                  type: 'button',
                  title: 'Type to search or click to open the dropdown',
                })}
              >
                <FeatherIcon name="ChevronDown" height={20} width={20} className="arrow" />
              </Arrow>
            </IconContainer>
          )}
        </InputContainer>

        <Menu {...getMenuProps()} hidden={!isOpen || !inputItems.length} css={css}>
          {isOpen &&
            inputItems.map((item, index) => (
              <ListItem
                highlighted={highlightedIndex === index ? true : false}
                key={`${item}${index}`}
                {...getItemProps({ item, index })}
              >
                {item.label}
              </ListItem>
            ))}
        </Menu>
      </MainContainer>
      {error && (
        <Text as="div" TextType={'inputError'}>
          {error}
        </Text>
      )}
    </>
  );
};

export default Combobox;
