var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"local"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.NEXT_PUBLIC_CLIENT_SENTRY_DSN;
const SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'local';
const TAG_VERSION = process.env.NEXT_PUBLIC_TAG_VERSION;

let IGNORED_ERRORS = [];
try {
  IGNORED_ERRORS = JSON.parse(process.env.NEXT_PUBLIC_SENTRY_IGNORED_ERRORS || '[]').map(
    (pattern: string) => new RegExp(pattern),
  );
  // eslint-disable-next-line no-empty
} catch (error) {}

if (SENTRY_DSN && SENTRY_ENVIRONMENT) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    tracesSampleRate: 1.0,
    release: TAG_VERSION,
    // NOTE: these errors are never even sent to Sentry so our quota
    // will not be used unlike ignoring errors through the Sentry UI.
    // Using environment variables here should let us toggle this
    // easily in case we want to check on suppressed errors
    ignoreErrors: [
      ...IGNORED_ERRORS,
      "undefined is not an object (evaluating 't[t.length-1].getDate')",
      'ResizeObserver loop completed with undelivered notifications.',
      "Cannot read properties of null (reading 'postMessage')",
    ],
    // NOTE: these urls are never sent to Sentry
    denyUrls: [/^https?:\/\/((cdn|www)\.)?data\.pendo\.io$/],
    beforeSend(event, hint) {
      const error = hint?.originalException as any;
      // NOTE: these errors are never sent to Sentry
      if (error?.ignoreErrorInSentry) return null;
      return event;
    },
    beforeSendTransaction(event) {
      if (event.transaction === '/admin/reset' || event.transaction === '/reset') {
        // Don't send the event to Sentry
        return null;
      }
      return event;
    },
  });
}
