import { styled } from 'stitches.config';

export const DocumentWrapper = styled('div', {
  cursor: 'grab',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  flex: 1,
  height: '792px',
  minWidth: '612px',
  width: '100%',
  overflow: 'hidden',
  border: '1px solid $grey250',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '-ms-overflow-style:': 'none',
  scrollbarWidth: 'none',
});

export const ZoomControlsWrapper = styled('div', {
  position: 'absolute',
  right: '0px',
  bottom: '0px',
  marginBottom: '42px',
  marginRight: '1rem',
  alignSelf: 'flex-end',
  justifySelf: 'flex-end',
});

export const MainWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  flex: '1',
  height: 'auto',
  maxWidth: '612px',
  '@mdMax': {
    height: '100vh',
    width: '100vw',
    border: 'none',
  },
});
