import FeatherIcon from '@components/FeatherIcon';
import { useRouter } from 'next/router';
import React from 'react';
import { styled } from 'stitches.config';
import Link from 'next/link';
import { ParsedUrlQueryInput } from 'querystring';

type History = {
  query?: ParsedUrlQueryInput;
  pathname: string;
  name: string;
  id: number | string;
  active: boolean;
  asPath?: string;
};
type Props = {
  history: History[];
  onBreadcrumbClick?: () => void;
};

const Navigation = styled('nav', {
  display: 'flex',
  alignItems: 'center',
  paddingBlock: 8,
  paddingInline: '$24',
});

const Arrow = styled(FeatherIcon, {
  color: '$grey400',
  marginInlineEnd: 8,
  cursor: 'pointer',
});

const List = styled('ul', {
  display: 'inline',
  paddingInlineStart: 0,
  margin: 0,
});

const Crumb = styled('li', {
  display: 'inline',
  '&:before': {
    content: '"\\00a0/\\00a0"', // forward slash ('/')
    paddingInline: 8,
  },
  '&:first-child:before': {
    content: '""',
    paddingInline: 0,
  },
});

const CustomLink = styled('a', {
  lineHeight: 1.5,
  color: '$grey400',
  background: 'transparent',
  border: 'none',
  outline: 'none',
  fontSize: '$body16',
  cursor: 'pointer',
  textDecoration: 'underline',
  padding: 0,
  variants: {
    active: {
      true: {
        textDecoration: 'none',
      },
    },
  },
});

/*
This component is connected to next/router. A pathname and (optionally) query are
passed to the next Link component href. When using this component, make sure to
push each route onto the next/router history stack with router.push()
*/
const Breadcrumb = ({ history, onBreadcrumbClick }: Props) => {
  const router = useRouter();

  return (
    <Navigation>
      <Arrow height={16} width={16} onClick={() => router.back()} name="ChevronLeft" />
      <List>
        {history.map((item) => {
          return (
            <Crumb key={item.id}>
              <Link
                passHref
                href={{
                  pathname: item.pathname,
                  query: item.query,
                }}
                as={item.asPath}
                legacyBehavior
              >
                <CustomLink
                  active={item.active}
                  onClick={() => {
                    if (onBreadcrumbClick) onBreadcrumbClick();
                  }}
                >
                  {item.name}
                </CustomLink>
              </Link>
            </Crumb>
          );
        })}
      </List>
    </Navigation>
  );
};

export default Breadcrumb;
