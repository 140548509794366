export const primaryPeacock = '#018e91';
export const newPrimaryLight = '#e6f4f4';
export const newPrimaryDark = '#017c7e';
export const secondary = '#06c8d1';
export const accentLight = '#ff9981';
export const accentDark = '#C91703';
export const lightBlue = '#adfbff';
export const lightYellow = '#fffecb';
export const lightRed = '#ffdbd2';
export const dark = '#859797';
export const light = '#d3d7db';
export const background = '#f3f4fa';
export const lightBackground = '#fafbff';
export const lightText = '#758585';
export const lightGreen = '#d8e3eb';
export const highlight = '#f7f8fe';
export const accentGreen = '#c7e1c9';
export const inputBorderColor = '#dadded';
// New palette
export const black = '#212429';
export const backgroundGrey = '#f2f2f2';
export const iconGrey = '#b5b5b5';
export const contrastGrey = '#777575';
export const hrGrey = '#dee6eb';
export const logoutGrey = '#707070';
export const darkGrey = '#606060';
export const dividerColor = '#b5b5b5';
export const scrollbarGrey = '#2e2f2f80'; // same as $text but with alpha = 0.5
export const white = '#ffffff';
export const cyberGrape = '#4a4177';
export const cyberGrapeLight = '#edecf1';
export const cyberGrapeLight1 = '#a5a0bb';
export const cyberGrapeDark = '#3a3264';
export const disabledGray = '#acb5bd';
export const grass = '#38976a';
export const grassLight = '#ebf5f0';
export const tableHeaderGrey = '#495057';
export const tableBorderGrey = '#dfe0eb';
export const paginationGrey = '#9fa2b4';
export const paginationDarkGrey = '#4b506d';
export const cardBackgroundGrey = '#f8f9fc';
export const coral = '#E0311A';
export const logoRed = '#F15D4A';
export const coralLight = '#feefed';
export const secondarySky = '#365c94';
export const secondarySkyLight = '#ebeff4';
export const pumpkin = '#FB7E21';
export const pumpkinPrimary = '#FB7E21';
export const pumpkinLight = '#FFF2E9';
export const peacockDark = '#017C7E';
export const grey3 = '#6E7681';
export const ripplingYellow = '#fdb71c';
export const yellow = '#FFFF00';
export const secondarySand = '#F4B96D';

const colors = {
  primaryPeacock,
  newPrimaryLight,
  newPrimaryDark,
  secondary,
  accentLight,
  accentDark,
  lightBlue,
  lightYellow,
  lightRed,
  dark,
  light,
  background,
  lightBackground,
  lightText,
  lightGreen,
  highlight,
  accentGreen,
  inputBorderColor,
  black,
  backgroundGrey,
  iconGrey,
  contrastGrey,
  hrGrey,
  logoutGrey,
  darkGrey,
  dividerColor,
  scrollbarGrey,
  white,
  cyberGrape,
  cyberGrapeLight,
  cyberGrapeLight1,
  cyberGrapeDark,
  disabledGray,
  grass,
  logoRed,
  grassLight,
  tableHeaderGrey,
  tableBorderGrey,
  paginationGrey,
  paginationDarkGrey,
  cardBackgroundGrey,
  coral,
  coralLight,
  secondarySky,
  secondarySkyLight,
  pumpkin,
  pumpkinPrimary,
  pumpkinLight,
  peacockDark,
  grey3,
  ripplingYellow,
  yellow,
  secondarySand,
};

export default colors;
