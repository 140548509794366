import type * as Stitches from '@stitches/react';
import type { ComponentPropsWithoutRef } from 'react';
import React from 'react';
import { styled } from 'stitches.config';

const Label = styled('label', {
  fontSize: '$body16',
  fontWeight: '$regular400',
  color: '$grey400',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 10,
});

const Input = styled('input', {
  minWidth: 24,
  width: 24,
  height: 24,
  cursor: 'pointer',
  '-webkit-appearance': 'none',
  appearance: 'none',
  margin: 0,
  background: `no-repeat center url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%20fill%3D%22none%22%3E%0A%20%20%3Crect%20x%3D%225%22%20y%3D%225%22%20width%3D%2222%22%20height%3D%2222%22%20rx%3D%223%22%20stroke%3D%22%236E7681%22%20stroke-width%3D%222%22%2F%3E%0A%3C%2Fsvg%3E")`,
  backgroundSize: 'auto',
  transform: 'translateY(-0.075em)',
  '&:checked': {
    background: `no-repeat center url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2034%2034%22%20fill%3D%22none%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M8%204C5.79086%204%204%205.79086%204%208V24C4%2026.2091%205.79086%2028%208%2028H24C26.2091%2028%2028%2026.2091%2028%2024V8C28%205.79086%2026.2091%204%2024%204H8ZM21.5134%2013.1007C21.9004%2012.7067%2021.8947%2012.0735%2021.5007%2011.6865C21.1067%2011.2995%2020.4735%2011.3052%2020.0866%2011.6993L14.2%2017.6929L11.9134%2015.3647C11.5265%2014.9707%2010.8933%2014.965%2010.4993%2015.352C10.1053%2015.739%2010.0996%2016.3721%2010.4866%2016.7661L13.4866%2019.8207C13.6746%2020.0121%2013.9317%2020.12%2014.2%2020.12C14.4683%2020.12%2014.7254%2020.0121%2014.9134%2019.8207L21.5134%2013.1007Z%22%20fill%3D%22%23018E91%22%2F%3E%0A%3C%2Fsvg%3E")`,
  },
  variants: {
    disabled: {
      true: {
        background: `no-repeat center url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%20fill%3D%22none%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M8%204C5.79086%204%204%205.79086%204%208V24C4%2026.2091%205.79086%2028%208%2028H24C26.2091%2028%2028%2026.2091%2028%2024V8C28%205.79086%2026.2091%204%2024%204H8ZM21.5134%2013.1007C21.9004%2012.7067%2021.8947%2012.0735%2021.5007%2011.6865C21.1067%2011.2995%2020.4735%2011.3052%2020.0866%2011.6993L14.2%2017.6929L11.9134%2015.3647C11.5265%2014.9707%2010.8933%2014.965%2010.4993%2015.352C10.1053%2015.739%2010.0996%2016.3721%2010.4866%2016.7661L13.4866%2019.8207C13.6746%2020.0121%2013.9317%2020.12%2014.2%2020.12C14.4683%2020.12%2014.7254%2020.0121%2014.9134%2019.8207L21.5134%2013.1007Z%22%20fill%3D%22%23D3D7DB%22%2F%3E%0A%20%20%3Cpath%20d%3D%22M20.8002%2012.4L14.2002%2019.12L11.2002%2016.0654%22%20stroke%3D%22white%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E")`,
        pointerEvents: 'none',
      },
    },
  },
});

const Note = styled('div', {
  marginLeft: 36,
  marginTop: -24,
  marginBottom: 10,
  fontSize: '$body12',
});

interface IProps extends ComponentPropsWithoutRef<'input'> {
  label?: string;
  disabled?: boolean;
  note?: string;
  css?: Stitches.CSS;
  checked?: boolean;
  newStyle?: boolean;
}

const CheckboxWithRef = ({ label, css, id, disabled, note, checked, ...rest }: IProps, ref) => {
  return (
    <>
      <Label css={css} htmlFor={id}>
        <Input id={id} data-testid={id} disabled={disabled} type="checkbox" checked={checked} ref={ref} {...rest} />
        {label}
      </Label>
      {note && <Note>{note}</Note>}
    </>
  );
};

const Checkbox = React.forwardRef<HTMLInputElement, IProps>(CheckboxWithRef);
export default Checkbox;
