import * as ReactTooltip from '@radix-ui/react-tooltip';
import React from 'react';
import { styled } from 'stitches.config';

interface Props extends React.ComponentPropsWithoutRef<typeof ReactTooltip.Tooltip> {
  children?: React.ReactChild | React.ReactChild[];
  content: string | React.ReactChild | React.ReactChild[];
  side?: 'top' | 'right' | 'bottom' | 'left';
  delayDuration?: number;
  open?: boolean;
  onOpenChange?: (args?: unknown) => void;
  avoidCollisions?: boolean;
  style?: React.CSSProperties;
}

const TooltipContent = styled(ReactTooltip.Content, {
  zIndex: 999999,
  background: '$black',
  color: '$grey100',
  padding: '4px 8px 2px 8px',
  borderRadius: 2,
  textAlign: 'center',
  fontSize: '$body12',
  fontWeight: '$semibold700',
});

const TooltipTrigger = styled(ReactTooltip.Trigger, {
  outline: 'none',
  border: 'none',
  background: 'transparent',
  padding: 0,
  lineHeight: 0,
});
/**
 * https://www.radix-ui.com/primitives/docs/components/tooltip#examples
 */
const Tooltip = ({
  children,
  content,
  delayDuration = 250,
  side = 'top',
  open,
  avoidCollisions = false,
  onOpenChange,
  ...TooltipContentProps
}: Props) => {
  return (
    <ReactTooltip.Provider>
      <ReactTooltip.Root delayDuration={delayDuration} open={open} onOpenChange={onOpenChange}>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <ReactTooltip.Portal>
          <TooltipContent
            side={side}
            avoidCollisions={avoidCollisions}
            collisionPadding={avoidCollisions ? 24 : 0}
            {...TooltipContentProps}
          >
            {content}
            <ReactTooltip.Arrow />
          </TooltipContent>
        </ReactTooltip.Portal>
      </ReactTooltip.Root>
    </ReactTooltip.Provider>
  );
};

export default Tooltip;
