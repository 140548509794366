import FeatherIcon, { IconName } from '@components/FeatherIcon';
import cx from 'classnames';
import React from 'react';

import styles from './Chip.module.scss';
interface IProps {
  children: string;
  color?: string;
  onClick?: (arg: any | undefined) => void;
  onChipClick?: (arg: any | undefined) => void;
  toggled?: boolean;
  className?: string;
}

// Choices: primaryPeacock, coral, plum

const Chip = ({ children, onClick, color = 'primaryPeacock', toggled, className, onChipClick }: IProps) => {
  let iconName: string;
  if (toggled === undefined) {
    iconName = 'XCircle';
  } else if (toggled === true) {
    iconName = 'MinusCircle';
  } else if (toggled === false) {
    iconName = 'PlusCircle';
  }
  return (
    <span
      className={cx(
        styles.chip,
        {
          [styles[color]]: true,
        },
        className,
      )}
      onClick={onChipClick}
    >
      <span>{children}</span>
      <FeatherIcon
        data-testid="chip-close-icon"
        onClick={onClick}
        name={iconName as IconName}
        className={styles.icon}
      />
    </span>
  );
};

export default Chip;
