import React from 'react';
import { keyframes, styled } from 'stitches.config';

const spin = keyframes({
  to: {
    transform: 'rotate(360deg)',
  },
});

const LoaderContainerFullPage = styled('div', {
  position: 'fixed',
  zIndex: '999',
  height: '$24',
  width: '$24',
  overflow: 'visible',
  margin: 'auto',
  top: '$0',
  left: '$0',
  bottom: '$0',
  right: '$0',
  '& svg': {
    animation: `${spin} 1s infinite linear`,
  },
});

const LoaderContainerButton = styled('div', {
  width: '$20',
  height: '$20',
  '& svg': {
    width: '$20',
    height: '$20',
    animation: `${spin} 1s infinite linear`,
  },
});

const Loader = () => {
  return (
    <LoaderContainerFullPage>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2V6" stroke="#4A4177" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 18V22" stroke="#8880A2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M4.93018 4.93018L7.76018 7.76018"
          stroke="#D6D4DF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.2402 16.2402L19.0702 19.0702"
          stroke="#766E94"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M2 12H6" stroke="#C2BDCF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18 12H22" stroke="#645B86" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M4.93018 19.0702L7.76018 16.2402"
          stroke="#9C95B2"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.2402 7.76018L19.0702 4.93018"
          stroke="#534A78"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </LoaderContainerFullPage>
  );
};

type IButtonLoaderProps = {
  color: 'white' | 'plum';
};
export const ButtonLoader = ({ color = 'white' }: IButtonLoaderProps) => {
  return (
    <LoaderContainerButton>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 2V6"
          stroke={color === 'white' ? 'white' : '#4A4177'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.6"
          d="M12 18V22"
          stroke={color === 'white' ? 'white' : '#8880A2'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.2"
          d="M4.92969 4.93018L7.75969 7.76018"
          stroke={color === 'white' ? 'white' : '#D6D4DF'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.7"
          d="M16.2402 16.2402L19.0702 19.0702"
          stroke={color === 'white' ? 'white' : '#766E94'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.3"
          d="M2 12H6"
          stroke={color === 'white' ? 'white' : '#C2BDCF'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.8"
          d="M18 12H22"
          stroke={color === 'white' ? 'white' : '#645B86'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.5"
          d="M4.92969 19.0702L7.75969 16.2402"
          stroke={color === 'white' ? 'white' : '#9C95B2'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.9"
          d="M16.2402 7.76018L19.0702 4.93018"
          stroke={color === 'white' ? 'white' : '#534A78'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </LoaderContainerButton>
  );
};

export default Loader;
