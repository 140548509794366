import React from 'react';
import * as icons from 'react-feather';
import { styled } from 'stitches.config';

export type IconName = keyof typeof icons;

export type IProps = {
  name: IconName;
  children?: any;
  onIconClick?: (event: any) => void;
  title?: string;
} & icons.IconProps;

const IconSpanWithTitleAttribute = styled('span', {
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
});

/**
 * Visual aid: https://feathericons.com/
 */
export default function FeatherIcon({ name, children, onIconClick, title = '', ...rest }: IProps) {
  const IconComponent = icons[name];

  const component = children ? (
    <IconComponent onClick={onIconClick} data-testid="feather-icon" {...rest}>
      {children}
    </IconComponent>
  ) : (
    <IconComponent onClick={onIconClick} data-testid="feather-icon" {...rest} />
  );
  const conditionallyWrappedComponent = title ? (
    <IconSpanWithTitleAttribute title={title}>{component}</IconSpanWithTitleAttribute>
  ) : (
    component
  );

  if (rest.href) {
    return (
      <a target="_blank" href={rest.href} rel="noreferrer">
        {conditionallyWrappedComponent}
      </a>
    );
  }

  return conditionallyWrappedComponent;
}
