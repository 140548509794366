import { styled } from 'stitches.config';

export const MainContainer = styled('div', {
  position: 'relative',
  variants: {
    disabled: {
      true: {
        cursor: 'auto',
        backgroundColor: '$grey100',
      },
    },
  },
});

export const InputContainer = styled('button', {
  position: 'relative',
  display: 'flex',
  backgroundColor: '$white',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: '1px solid $grey200',
  borderRadius: 4,
  padding: 0,
  minHeight: 45,
  width: 400, // override this width css
  gap: 4,
  cursor: 'pointer',
  '&:focus-within': {
    border: '1px solid $primaryPeacock',
  },
  '& .clear-icon': {
    verticalAlign: 'middle',
    cursor: 'pointer',
    color: '$grey300',
  },
  variants: {
    error: {
      true: {
        border: '1px solid $primaryCoral',
        outline: 'none',
        color: '$grey250',
        '&:focus': {
          border: '1px solid $primaryCoral',
        },
      },
    },
    label: {
      true: {
        marginTop: 8,
      },
    },
    disabled: {
      true: {
        cursor: 'not-allowed',
        backgroundColor: '$grey100',
        '&:focus-within': {
          border: '1px solid $grey200',
        },
      },
    },
  },
});

export const SelectContainer = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: 2,
  padding: '12px 12px 9px 12px',
  background: '$white',
  fontSize: '$body16',
  color: '$black',
  width: '100%',
  border: 'none',
  borderRadius: 4,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  cursor: 'pointer',
  variants: {
    disabled: {
      true: {
        cursor: 'not-allowed',
        backgroundColor: '$grey100',
      },
    },
  },
});

export const Input = styled('input', {
  padding: '12px 12px 9px 12px',
  background: '$white',
  fontSize: '$body16',
  color: '$black',
  width: '100%',
  border: 'none',
  borderRadius: 4,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  '&::placeholder': {
    color: '$grey250',
  },
  '&::-webkit-input-placeholder': {
    color: '$grey250',
  },
  '&::-moz-placeholder': {
    color: '$grey250',
  },
  '&:focus-visible': {
    outline: 'none',
  },
  '&:disabled': {
    cursor: 'not-allowed',
    backgroundColor: '$grey100',
    '&:focus-within': {
      border: '1px solid $grey200',
    },
  },
});

export const Arrow = styled('button', {
  border: 'none',
  background: 'none',
  cursor: 'inherit',
  padding: 0,
  margin: 0,
  outline: 'none',
});

export const IconContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  '& .arrow': {
    verticalAlign: 'middle',
    color: '$primaryPeacock',
    marginInlineEnd: 12,
    cursor: 'inherit',
  },
});

export const Menu = styled('ul', {
  position: 'absolute',
  zIndex: 99,
  backgroundColor: '$white',
  border: '1px solid $grey250',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14)',
  padding: '8px 1px',
  listStyle: 'none',
  maxHeight: 215,
  margin: 0,
  width: 400,
  overflow: 'auto',
  marginTop: 4,
  variants: {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const ListItem = styled('li', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  cursor: 'pointer',
  padding: '0.5rem 1.5rem 0.5rem 0.75rem',
  gap: '0.5rem',
  variants: {
    highlighted: {
      true: {
        backgroundColor: '$grey100',
        color: '$primaryPeacock',
      },
    },
    selected: {
      true: {
        color: '$primaryPeacock',
      },
    },
  },
  '& .checkmark': {
    color: '$primaryPeacock',
  },
});

export const SelectedItem = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '0.5rem',
});
