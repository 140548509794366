import React, { useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import { DocumentWrapper, MainWrapper, ZoomControlsWrapper } from './components';
import { PaginationAndFileSelect } from './components/controls/PaginationAndFileSelect';
import { ZoomAndRotateControls } from './components/controls/ZoomControls';
import { PdfRenderer } from './components/PdfViewer';

interface IProps {
  file: {
    url: string;
    extension: string;
  };
}
const PdfViewer = ({ file }: IProps) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [isPdfLoaded, setIsPdfLoaded] = useState(false);
  const handleLoad = () => {
    setIsPdfLoaded(true);
  };
  const handleRotateClockwise = () => {
    setRotation((rotation + 90) % 360);
  };
  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handleFirstPage = () => {
    if (pageNumber !== 1) {
      setPageNumber(1);
    }
  };
  const handleLastPage = () => {
    if (pageNumber !== numPages) {
      setPageNumber(numPages);
    }
  };
  if (!file) return null;

  return (
    <>
      <MainWrapper className="main-container">
        <DocumentWrapper className="document-container">
          <TransformWrapper
            minPositionX={-100}
            maxPositionX={100}
            minPositionY={-100}
            maxPositionY={100}
            initialScale={1}
            centerZoomedOut={false}
            centerOnInit={isPdfLoaded}
            limitToBounds={false}
          >
            {({ zoomIn, zoomOut, resetTransform }) => (
              <>
                <TransformComponent>
                  <PdfRenderer
                    onLoad={handleLoad}
                    setNumPages={setNumPages}
                    url={file?.url}
                    pageNumber={pageNumber}
                    rotation={rotation}
                  />
                </TransformComponent>
                {file?.url ? (
                  <ZoomControlsWrapper>
                    <ZoomAndRotateControls
                      onRotateClockwise={handleRotateClockwise}
                      onZoomIn={() => zoomIn()}
                      onZoomOut={() => zoomOut()}
                      onReset={() => {
                        resetTransform();
                        setRotation(0);
                      }}
                    />
                  </ZoomControlsWrapper>
                ) : null}
              </>
            )}
          </TransformWrapper>
        </DocumentWrapper>
      </MainWrapper>
      <PaginationAndFileSelect
        onPreviousPage={handlePreviousPage}
        onNextPage={handleNextPage}
        totalPages={numPages}
        currentPage={pageNumber}
        setToFirstPage={handleFirstPage}
        setToLastPage={handleLastPage}
      />
    </>
  );
};

export default React.memo(PdfViewer);
