import colors from '@constants/colors';
import cx from 'classnames';
import { ISelectOption } from '@constants/types';
import React from 'react';
import Select from 'react-select';

import styles from './Select.module.scss';

interface IProps {
  label?: string;
  id?: string;
  onChange?: (x: ISelectOption) => void;
  defaultValue?: ISelectOption | null;
  value?: ISelectOption | null;
  error?: string | null;
  placeholder?: string;
  options: ISelectOption[];
  containerClassName?: string;
}

const selectStyles = (error = null) => {
  return {
    control: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: '#fff',
        height: '45px',
        minWidth: '400px',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: error || !isFocused ? 'none' : `0 0 0 1px ${colors.primaryPeacock}`,
        borderColor: error ? colors.coral : colors.hrGrey,
        '&:hover': {
          borderColor: error ? colors.coral : colors.hrGrey,
        },
      };
    },
    placeholder: (styles) => {
      return {
        ...styles,
        position: 'relative',
        color: colors.disabledGray,
        fontSize: '16px',
        lineHeight: '24px',
      };
    },
    dropdownIndicator: (styles) => ({
      ...styles,
      color: colors.primaryPeacock,
      '&:hover': {
        color: colors.primaryPeacock,
      },
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      cursor: 'pointer',
      color: isSelected ? colors.primaryPeacock : colors.black,
      backgroundColor: isSelected ? colors.cardBackgroundGrey : colors.white,
      '&:hover': {
        backgroundColor: colors.cardBackgroundGrey,
        color: colors.primaryPeacock,
      },
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 2,
    }),
  };
};
const SelectComponentWithRef = ({ label, id, containerClassName, error, options, ...rest }: IProps, ref) => {
  return (
    <div className={cx(styles.container, containerClassName)}>
      {label && <label htmlFor={id}>{label}</label>}
      <Select styles={selectStyles(error)} options={options} {...rest} ref={ref} />
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

const SelectComponent = React.forwardRef<HTMLInputElement, IProps>(SelectComponentWithRef);

export default SelectComponent;
