import { styled } from 'stitches.config';

export const Text = styled('span', {
  fontFamily: '$nunito',
  variants: {
    TextType: {
      nunito12: {
        fontSize: '$body12',
        fontWeight: '$regular400',
        color: '$grey300',
        padding: 0,
        lineHeight: 1.5,
      },
      nunito14: {
        fontSize: '$body14',
        fontWeight: '$regular400',
        color: '$black',
        padding: 0,
        lineHeight: 1.5,
      },
      nunito16: {
        fontSize: '$body16',
        fontWeight: '$regular400',
        color: '$black',
        padding: 0,
        lineHeight: 1.5,
      },
      nunito18: {
        fontSize: '$body18',
        fontWeight: '$regular400',
        color: '$black',
        padding: 0,
        lineHeight: 1.5,
      },
      nunito24: {
        fontSize: '$heading24',
        fontWeight: '$regular400',
        color: '$black',
        padding: 0,
        lineHeight: 1.5,
      },
      heading: {
        fontSize: '$heading40',
        color: '$black',
        fontFamily: '$Poppins',
        fontWeight: '$medium500',
        marginBottom: '$8',
      },
      heading20: {
        fontSize: '$heading20',
        fontFamily: '$Poppins',
        padding: 0,
        color: '$black',
      },
      heading24: {
        fontSize: '$heading24',
        fontFamily: '$Poppins',
        padding: 0,
        color: '$black',
      },
      subHeading: {
        fontSize: '$body16',
        fontWeight: '$regular400',
        color: '$grey300',
      },
      sectionHeading: {
        width: '85px',
        marginBottom: '$16',
        fontSize: '$body14',
        color: '$grey400',
        fontWeight: '$semiBold700',
      },
      colorName: {
        color: '$grey400',
        fontSize: '$body12',
        fontWeight: '$semiBold700',
        marginTop: '0.5rem',
        marginBottom: '0.25rem',
      },
      colorHash: {
        color: '$disabledGrey',
        fontWeight: '$semiBold700',
        fontSize: '$body12',
        marginBottom: '0.25rem',
      },
      inputLabel: {
        fontSize: '$body16',
        lineHeight: 1.5,
        fontFamily: '$Nunito',
        fontWeight: '$semibold700',
        color: '$grey400',
      },
      inputError: {
        color: '$primaryCoral',
        fontSize: '$body12',
        lineHeight: 1.125,
      },
      inputPlaceholder: {
        fontSize: '$body16',
        lineHeight: 1.5,
        fontFamily: '$Nunito',
        fontWeight: '$regular400',
        color: '$grey250',
      },
      allCaps12ptHeader: {
        fontSize: '$body12',
        lineHeight: 1.5,
        fontFamily: '$Nunito',
        fontWeight: '$semibold700',
        textTransform: 'uppercase',
        color: '$grey350',
      },
    },
  },
});

export const ModalHeader = styled('h3', {
  fontSize: '$heading24',
  fontFamily: '$Poppins',
  fontWeight: '$regular400',
  color: '$black',
  lineHeight: 2.25, // 36px / 16px
  margin: 0,
  padding: 24,
});

export const ModalError = styled('p', {
  fontSize: '$body14',
  color: '$primaryCoral',
  paddingInline: 24,
  paddingBlockEnd: 24,
  margin: 0,
});
