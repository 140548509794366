import FeatherIcon from '@components/FeatherIcon';
import {
  IconContainer,
  InputContainer,
  ListItem,
  MainContainer,
  Menu,
  SelectContainer,
  SelectedItem,
} from '@components/v2/StyleGuide/ColorGuide/components/DropdownStyles';
import { Text } from '@components/v2/StyleGuide/ColorGuide/components/Text';
import type * as Stitches from '@stitches/react';
import { ISelectOption } from '@constants/types';
import { useSelect } from 'downshift';
import React from 'react';
import { RefCallBack } from 'react-hook-form';

type Props = {
  items: ISelectOption[];
  label?: string | React.ReactElement;
  dataTestId?: string;
  css?: Stitches.CSS;
  placeholder?: string;
  clearable?: boolean;
  error?: string;
  selectedItem?: ISelectOption;
  disabled?: boolean;
  id?: string;
  onSelectedItemChange: (item: ISelectOption) => void;
  optionsDecorators?: {
    component: JSX.Element;
    key: number;
    icon: string;
    iconColor: string;
  }[];
};

const NewSelect = React.forwardRef(
  (
    {
      label,
      dataTestId,
      items,
      css,
      placeholder,
      error,
      selectedItem,
      clearable = true,
      onSelectedItemChange,
      disabled = false,
      optionsDecorators,
      id = undefined,
    }: Props,
    ref: RefCallBack,
  ) => {
    const itemToString = (item) => item.label;
    const { isOpen, getToggleButtonProps, getLabelProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
      items,
      itemToString,
      onStateChange: ({ type, selectedItem: si }) => {
        if (type === useSelect.stateChangeTypes.ItemClick && !si) {
          onSelectedItemChange(items[highlightedIndex]);
        }
      },
      onSelectedItemChange: ({ selectedItem }) => {
        onSelectedItemChange(selectedItem);
      },
    });

    return (
      <MainContainer css={css} data-testid={dataTestId} id={id && id}>
        {label && (
          <>
            {selectedItem?.component ? selectedItem.component : null}
            <Text css={css} TextType="inputLabel" as="label" {...getLabelProps()}>
              {label}
            </Text>
          </>
        )}
        <InputContainer
          data-testid={`${dataTestId}-inputContainer`}
          error={!!error}
          disabled={disabled}
          label={!!label}
          id={`${id}-inputContainer`}
          css={css}
          ref={ref}
          {...getToggleButtonProps({
            disabled,
            title: 'Click to open the dropdown',
            type: 'button',
          })}
        >
          <SelectContainer disabled={disabled} placeholder={placeholder}>
            <span data-testid={`${dataTestId}-selected-item`}>
              {selectedItem && Object.keys(selectedItem).length > 0 ? (
                <SelectedItem>
                  {optionsDecorators?.find((option) => option.key === selectedItem.value)?.component ?? null}
                  {itemToString(selectedItem)}
                </SelectedItem>
              ) : placeholder ? (
                <Text TextType="inputPlaceholder">{placeholder}</Text>
              ) : (
                ''
              )}
            </span>
          </SelectContainer>
          <IconContainer>
            {(selectedItem?.value || selectedItem?.value == 0) && clearable ? (
              <FeatherIcon
                onClick={() => {
                  onSelectedItemChange({} as ISelectOption);
                }}
                name="X"
                height={16}
                width={16}
                className="clear-icon"
              />
            ) : null}

            <FeatherIcon name="ChevronDown" height={20} width={20} className="arrow" />
          </IconContainer>
        </InputContainer>
        {error && <Text TextType="inputError">{error}</Text>}
        <Menu {...getMenuProps()} hidden={!isOpen} css={css} data-testid={`${dataTestId}-menu`}>
          {isOpen &&
            items.map((item, index) => {
              return (
                <ListItem
                  css={css}
                  selected={selectedItem?.value == item.value}
                  highlighted={highlightedIndex === index}
                  key={`${item}${index}`}
                  {...getItemProps({ item, index })}
                >
                  {optionsDecorators?.find((option) => option.key === item.value)?.component ?? null}
                  {item.label}
                </ListItem>
              );
            })}
        </Menu>
      </MainContainer>
    );
  },
);
NewSelect.displayName = 'NewSelect';
export default NewSelect;
