import { Root } from '@radix-ui/react-dropdown-menu';
import React from 'react';

interface IProps extends React.ComponentPropsWithoutRef<typeof Root> {
  id?: string;
  open?: boolean;
  onOpenChange?: () => void;
  children?: React.ReactChild | React.ReactChild[];
}

/**
 * https://www.radix-ui.com/docs/primitives/components/dropdown-menu#root
 */
export const DropdownMenuRoot = ({ open, onOpenChange, children, id, ...rest }: IProps) => {
  function onChange() {
    // This looks insane and it is, but there's a good reason for this. The dropdown menu adds an
    // overlay and uses react-remove-scroll which adds a margin to the body when the menu is opened,
    // causing jumpiness, and this is the only strategy that works for us
    // https://github.com/radix-ui/primitives/discussions/1100
    document.body.setAttribute('style', 'margin-inline-end: 0px !important');
    if (onOpenChange) onOpenChange();
  }
  return (
    // @ts-ignore
    <Root id={id} data-testid="dropdown-menu-root" open={open} onOpenChange={onChange} {...rest}>
      {children}
    </Root>
  );
};
