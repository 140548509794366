/* eslint-disable react/destructuring-assignment */
import isEqual from 'lodash.isequal';
import Mousetrap from 'mousetrap';
import PropTypes from 'prop-types';
import React from 'react';

class KeyTrap extends React.Component {
  constructor(props) {
    super(props);
    this.handleEvent = this.handleEvent.bind(this);
  }

  componentDidMount() {
    Mousetrap.bind(this.props.on, this.handleEvent);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.on, this.props.on)) {
      Mousetrap.unbind(this.prevProps.on);
      Mousetrap.bind(this.props.on, this.handleEvent);
    }
  }

  componentWillUnmount() {
    Mousetrap.unbind(this.props.on);
  }

  handleEvent(event) {
    this.props.handleEvent(event);
  }

  render() {
    return null;
  }
}

KeyTrap.propTypes = {
  handleEvent: PropTypes.func.isRequired,
  on: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default KeyTrap;
