import type { ComponentPropsWithRef } from 'react';
import React from 'react';
import { styled } from 'stitches.config';

interface IProps extends ComponentPropsWithRef<'textarea'> {
  id: string;
  label?: string;
  error?: string;
  rows?: number;
  cols?: number;
  helperText?: string;
  css?: Record<string, unknown>;
}

const Label = styled('label', {
  paddingBlockStart: 16,
  paddingBlockEnd: 8,
  paddingInline: 0,
  color: '$grey400',
  display: 'block',
});

const Textarea = styled('textarea', {
  borderRadius: 4,
  border: '1px solid $grey200',
  padding: 12,

  '&:disabled': {
    backgroundColor: '$grey100',
  },

  '&:placeholder': {
    color: '$grey300',
  },
});

const Error = styled('p', {
  fontSize: '$body12',
  color: '$primaryCoral',
  margin: 0,
  paddingBlockStart: 8,
  marginTop: '-6px',
});

const HelperText = styled('p', {
  fontSize: '$body12',
  color: '$grey300',
  margin: 0,
  paddingBlockStart: 8,
  marginTop: '-6px',
});

const TextArea = (
  { id, rows = 5, cols = 50, label, error, helperText, ...rest }: IProps,
  ref: React.RefObject<HTMLTextAreaElement>,
) => {
  return (
    <div>
      {label && <Label htmlFor={id}>{label}</Label>}
      <Textarea id={id} rows={rows} cols={cols} ref={ref} {...rest} />
      {helperText && <HelperText>{helperText}</HelperText>}
      {error && <Error>{error}</Error>}
    </div>
  );
};

export default React.forwardRef(TextArea);
