import { Root } from '@radix-ui/react-popover';
import React from 'react';

interface IProps extends React.ComponentPropsWithoutRef<typeof Root> {
  children?: React.ReactChild | React.ReactChild[];
}

/**
 * https://www.radix-ui.com/primitives/docs/components/popover#root
 */
export const PopoverRoot = ({ children, ...rest }: IProps) => (
  <Root data-testid="popover-root" {...rest}>
    {children}
  </Root>
);
