import FeatherIcon from '@components/FeatherIcon';
import { styled } from 'stitches.config';

//pagination components:
export const PaginationSection = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  width: '38rem',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '16px',
  gap: '1rem',
});

export const PaginationIcon = styled(FeatherIcon, {
  color: '$grey250',
  cursor: 'pointer',
});
export const PaginationTypography = styled('p', {
  color: '$grey400',
  wrap: 'nowrap',
  whiteSpace: 'nowrap',
});
export const PaginationIconWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});
