import { ButtonLoader } from '@components/v2/Loader/Loader';
import type * as Stitches from '@stitches/react';
import type { ComponentPropsWithRef } from 'react';
import React from 'react';
import { styled } from 'stitches.config';

export enum ButtonColor {
  PEACOCK = 'peacock',
  EGGPLANT = 'eggplant',
  OUTLINE = 'outline',
  OUTLINE_SKY = 'outlineSky',
  OUTLINE_EGGPLANT_LIGHT = 'outlineEggplantLight',
  CORAL = 'coral',
  OUTLINE_CORAL = 'outlineCoral',
  GRASS = 'grass',
  TEXT_CORAL = 'textCoral',
  TEXT_PEACOCK = 'textPeacock',
  LIGHT_PEACOCK = 'lightPeacock',
  NONE = 'none',
  ICON_WRAPPER = 'iconWrapper',
}

export const StyledButton = styled('button', {
  padding: '$12 $24',
  borderRadius: '$4',
  fontFamily: '$Nunito',
  fontWeight: '$extrabold800',
  textTransform: 'uppercase',
  fontSize: '$body16',
  lineHeight: 'initial',
  border: 'none',
  letterSpacing: '0.08em',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  outline: 'none',
  '&:disabled': {
    cursor: 'not-allowed',
  },
  variants: {
    unstyledWrapper: {
      true: {
        all: 'unset',
        width: '100%',
        cursor: 'pointer',
        '&:focus, &:focus-visible': {
          outline: '2px solid $primaryPeacockDark',
        },
      },
    },
    color: {
      iconWrapper: {
        width: 'fit-content',
        lineHeight: 0,
        background: 'transparent',
        color: '$grey300',
        padding: '$4',
        '&:hover': {
          color: '$grey400',
          background: 'transparent',
        },
        '&:focus, &:focus-visible': {
          outline: '2px solid $primaryPeacockDark',
        },
      },
      peacock: {
        backgroundColor: '$primaryPeacockDark',
        color: '$white',
        '&:hover': {
          backgroundColor: '$primaryPeacockDarker',
        },
        '&:active': {
          backgroundColor: '$primaryPeacockMedium',
        },
        '&:disabled': {
          backgroundColor: '$grey200',
        },
        '&:focus, &:focus-visible': {
          backgroundColor: '$primaryPeacockMedium',
          outline: '2px solid $primaryPeacockDark',
        },
      },
      lightPeacock: {
        backgroundColor: '$primaryPeacockLight',
        color: '$primaryPeacockMedium',
        '&:focus, &:focus-visible': {
          outline: '2px solid $primaryPeacockDark',
        },
      },
      eggplant: {
        backgroundColor: '$primaryEggplant',
        color: '$white',
        '&:hover': {
          backgroundColor: '$primaryEggplantDark',
        },
        '&:active': {
          backgroundColor: '$primaryEggplantMedium',
        },
        '&:disabled': {
          backgroundColor: '$grey200',
        },
        '&:focus, &:focus-visible': {
          outline: '2px solid $primaryEggplantDark',
          backgroundColor: '$primaryEggplantMedium',
        },
      },
      coral: {
        backgroundColor: '$primaryCoral',
        color: '$white',
        '&:hover': {
          backgroundColor: '$primaryCoralDark',
        },
        '&:active': {
          backgroundColor: '$primaryCoralMedium',
        },
        '&:disabled': {
          backgroundColor: '$grey200',
        },
        '&:focus, &:focus-visible': {
          backgroundColor: '$primaryCoralMedium',
          outline: '2px solid $primaryCoralDark',
        },
      },
      outlineCoral: {
        backgroundColor: '$white',
        color: '$primaryCoral',
        boxShadow: 'inset 0 0 0 1px $primaryCoral',
        '&:hover': {
          backgroundColor: '$primaryCoralLight',
        },
        '&:disabled': {
          backgroundColor: '$white',
          boxShadow: 'inset 0 0 0 1px $grey200',
        },
        '&:focus, &:focus-visible': {
          backgroundColor: '$white',
          outline: '2px solid $primaryCoral',
        },
      },
      grass: {
        backgroundColor: '$secondaryGrass',
        color: '$white',
        '&:hover': {
          opacity: 0.8,
        },
        '&:active': {
          backgroundColor: '$secondaryGrass',
        },
        '&:disabled': {
          backgroundColor: '$grey200',
        },
        '&:focus, &:focus-visible': {
          backgroundColor: '$secondaryGrass',
          outline: '2px solid $secondaryGrass',
        },
      },
      outline: {
        backgroundColor: '$white',
        color: '$primaryEggplant',
        boxShadow: 'inset 0 0 0 1px $primaryEggplantLight',
        '&:hover': {
          backgroundColor: '$primaryEggplantLight',
        },
        '&:active': {
          backgroundColor: '$primaryEggplantMedium',
        },
        '&:disabled': {
          backgroundColor: '$white',
          boxShadow: 'inset 0 0 0 1px $grey200',
        },
        '&:focus, &:focus-visible': {
          backgroundColor: '$primaryEggplantLight',
          outline: '2px solid $primaryEggplantDark',
        },
      },
      outlineSky: {
        backgroundColor: '$white',
        color: '$secondarySky',
        boxShadow: 'inset 0 0 0 1px $secondarySky',
        '&:hover': {
          backgroundColor: '$secondarySkyLight',
        },
        '&:disabled': {
          backgroundColor: '$white',
          boxShadow: 'inset 0 0 0 1px $grey200',
        },
        '&:focus, &:focus-visible': {
          backgroundColor: '$white',
          outline: '2px solid $secondarySky',
        },
      },
      outlineEggplantLight: {
        backgroundColor: '$white',
        color: '$primaryEggplant',
        boxShadow: 'inset 0 0 0 1px $primaryEggplantLight',
        '&:hover': {
          backgroundColor: '$primaryEggplantLight',
        },
        '&:active': {
          backgroundColor: '$primaryEggplantMedium',
          color: '$primaryEggplant',
        },
        '&:disabled': {
          color: '$grey300',
          backgroundColor: '$white',
          boxShadow: 'inset 0 0 0 1px $grey200',
        },
        '&:focus, &:focus-visible': {
          backgroundColor: '$primaryEggplantLight',
          outline: '2px solid $primaryEggplant',
        },
      },
      textPeacock: {
        color: '$primaryPeacockDark',
        backgroundColor: 'transparent',
        textTransform: 'none',
        textDecoration: 'none',
        letterSpacing: 'initial',
        fontWeight: '$medium500',
        padding: '0 $8',
        '&:hover': {
          color: '$primaryPeacockDarker',
          cursor: 'pointer',
        },
        '&:active': {
          color: '$primaryPeacockMedium',
        },
        '&:disabled': {
          color: '$grey200',
        },
        '&:focus, &:focus-visible': {
          outline: '2px solid $primaryPeacockDarker',
        },
      },
      textCoral: {
        backgroundColor: 'transparent',
        color: '$primaryCoral',
        border: 'none',
        '&:hover': {
          backgroundColor: '$primaryCoralLight',
        },
        '&:active': {
          backgroundColor: '$primaryCoralLight',
        },
        '&:disabled': {
          backgroundColor: 'transparent',
        },
        '&:focus, &:focus-visible': {
          outline: '2px solid $primaryCoralLight',
        },
      },
      none: {
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&:active': {
          backgroundColor: 'transparent',
        },
        '&:disabled': {
          backgroundColor: 'transparent',
        },
      },
    },
    loading: {
      true: {
        cursor: 'auto',
        gap: '10px',
        opacity: '0.75',
      },
    },
    fullWidth: {
      true: {
        width: '100%',
      },
    },
    size: {
      small: {
        padding: '$6 $12',
        fontSize: 14,
      },
    },
    icon: {
      true: {
        gap: 8,
      },
    },
  },
  defaultVariants: {
    color: ButtonColor.PEACOCK,
  },
});

export enum ButtonSize {
  SMALL = 'small',
}

export type Ref = HTMLButtonElement;
export interface ButtonProps extends ComponentPropsWithRef<'button'> {
  color?: ButtonColor | string;
  as?: string | React.ComponentType<any>;
  loading?: boolean;
  icon?: boolean;
  fullWidth?: boolean;
  css?: Stitches.CSS;
  size?: ButtonSize;
  unstyledWrapper?: boolean;
  id?: string;
}

const Button = (
  {
    children,
    unstyledWrapper = false,
    loading = false,
    color,
    fullWidth = false,
    size,
    as,
    id = undefined,
    ...rest
  }: ButtonProps,
  ref,
) => {
  // TODO LEX-255 adjust the button loader colors to match the button colors
  return (
    <StyledButton
      as={as}
      loading={loading}
      unstyledWrapper={unstyledWrapper}
      aria-disabled={rest?.disabled || loading}
      color={color as ButtonColor}
      fullWidth={fullWidth}
      ref={ref}
      size={size}
      id={id}
      {...rest}
    >
      {children}
      {loading && (
        <ButtonLoader color={color === ButtonColor.OUTLINE || color === ButtonColor.OUTLINE_SKY ? 'plum' : 'white'} />
      )}
    </StyledButton>
  );
};

export default React.forwardRef(Button);
