import * as Dialog from '@radix-ui/react-dialog';
import * as Stitches from '@stitches/react';
import useOnClickOutside from 'hooks/useOnOutsideClick';
import React, { useRef } from 'react';
import { styled } from 'stitches.config';

const Overlay = styled(Dialog.Overlay, {
  '@mdMax': {
    boxShadow: 'none',
  },
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  boxShadow: '-2px 2px 6px 0 rgb(0 0 0 / 10%)',
  zIndex: 100,
  background: 'rgba(0, 0, 0, 0.5)',
});

const Content = styled(Dialog.Content, {
  '@mdMax': {
    width: '100%',
  },
  backgroundColor: '$white',
  position: 'fixed',
  zIndex: 99999,
  width: '33%',
  minWidth: 320,
  overflow: 'auto',
  top: 0,
  bottom: 0,
  right: 0,
  variants: {
    wide: {
      true: {
        minWidth: '35.75rem',
      },
    },
  },
});

interface IProps {
  children: React.ReactNode | React.ReactNode[];
  open: boolean;
  onClose: () => void;
  wide?: boolean;
  css?: Stitches.CSS;
  overlayCss?: Stitches.CSS;
  disableOutsideClick?: boolean;
}

const Drawer = ({ children, onClose, open, css, overlayCss, wide = false, disableOutsideClick = false }: IProps) => {
  const ref = useRef();
  useOnClickOutside(ref, () => {
    // SHARE-1334 this is a hack to make sure the drawer does not close
    // when a modal is open on top of it and you click outside of it
    if (!disableOutsideClick) onClose();
  });
  return (
    <Dialog.Root open={open}>
      <Overlay css={overlayCss} />
      <Content css={css} wide={wide} ref={ref}>
        {children}
      </Content>
    </Dialog.Root>
  );
};

export default Drawer;
