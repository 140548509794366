import { Overlay } from '@radix-ui/react-dialog';
import React from 'react';
import { styled } from 'stitches.config';

/*
ref: https://www.radix-ui.com/docs/primitives/components/dialog#overlay
*/
const Component = styled(Overlay, {
  background: 'rgba(0 0 0 / 0.5)',
  position: 'fixed',
  inset: '0',
  overflowY: 'hidden',
  zIndex: '999999', // This is an order of magnitude higher than the other modals
});

interface IProps {
  children: React.ReactNode;
}
const ModalOverlay = ({ children }: IProps, ref: React.MutableRefObject<HTMLDivElement>) => (
  <Component data-testid="modal-overlay" ref={ref}>
    {children}
  </Component>
);

export default React.forwardRef(ModalOverlay);
