import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';

import users, { rootSaga as usersSaga } from './users';

export function* rootSaga() {
  return yield all([usersSaga()]);
}

export default combineReducers({
  users,
});
