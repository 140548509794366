import Cookies from 'js-cookie';

import { getSubdomain } from '../utils/subdomain';

const ACCESS_TOKEN = 'tilt-access-token';
const REFRESH_TOKEN = 'tilt-refresh-token';
const IMPERSONATE_ACCESS_TOKEN = 'tilt-impersonate-token';
const IMPERSONATE_SUBDOMAIN = 'tilt-impersonate-subdomain';
const DEVICE_UID = 'deviceUID'; // can we change this w/o breaking MFA?
const TWO_FACTOR_IGNORE_PAGE = '2fa-ignore-page';

export function getAccessToken() {
  if (typeof window !== 'undefined') {
    const token = window.localStorage.getItem(ACCESS_TOKEN);
    const impersonateToken = window.localStorage.getItem(IMPERSONATE_ACCESS_TOKEN);
    const impersonateSubdomain = window.localStorage.getItem(IMPERSONATE_SUBDOMAIN);
    if (!token && impersonateToken && impersonateSubdomain === getSubdomain()) {
      return impersonateToken;
    } else {
      return token;
    }
  }
}

export function get2faIgnorePage() {
  return typeof window !== 'undefined' && window.localStorage.getItem(TWO_FACTOR_IGNORE_PAGE);
}

export function set2faIgnorePage(value) {
  if (typeof window !== 'undefined') {
    if (!value) {
      window.localStorage.removeItem(TWO_FACTOR_IGNORE_PAGE);
    } else {
      window.localStorage.setItem(TWO_FACTOR_IGNORE_PAGE, value);
    }
  }
}

export function getDeviceUID() {
  return typeof window !== 'undefined' && window.localStorage.getItem(DEVICE_UID);
}

export function saveImpersonateAccessToken(accessToken) {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(IMPERSONATE_ACCESS_TOKEN, accessToken);
    window.localStorage.setItem(IMPERSONATE_SUBDOMAIN, getSubdomain());
  }
}

// TODO LIFE-786: implement refresh tokens (backend is currently not properly adhering to JWT implementation)
export function saveRefreshToken(refreshToken) {
  if (typeof window !== 'undefined') {
    if (!refreshToken) {
      window.localStorage.removeItem(REFRESH_TOKEN);
    } else {
      window.localStorage.setItem(REFRESH_TOKEN, refreshToken);
    }
  }
}

export function saveAccessToken(accessToken) {
  if (typeof window !== 'undefined') {
    if (!accessToken) {
      window.localStorage.removeItem(ACCESS_TOKEN);
    } else {
      window.localStorage.setItem(ACCESS_TOKEN, accessToken);
    }
    // defensive removal of access_token cookie
    Cookies.remove('access_token', {
      path: '/',
      domain: process.env.NEXT_PUBLIC_SITE_DOMAIN,
    });
  }
}

export function saveDeviceUID(deviceUID) {
  if (deviceUID && typeof window !== 'undefined') {
    window.localStorage.setItem(DEVICE_UID, deviceUID);
  }
}

module.exports = {
  saveRefreshToken,
  saveAccessToken,
  saveDeviceUID,
  saveImpersonateAccessToken,
  getAccessToken,
  getDeviceUID,
  get2faIgnorePage,
  set2faIgnorePage,
};
