import React from 'react';
import { styled } from 'stitches.config';

export const StyledContentPanel = styled('div', {
  display: 'flex',
  padding: '$28',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '$16',
  alignSelf: 'stretch',
  borderRadius: '$8',
  border: '1px solid $grey200',
  background: '$white',
  marginTop: '$24',
  variants: {
    color: {
      outline: {
        border: '1px solid $grey200',
        background: '$white',
      },
      filled: {
        border: 'none',
        background: '$grey100',
      },
    },
    headerBorder: {
      true: {
        '& > header': {
          paddingBottom: '$16',
          borderBottom: '1px solid $grey200',
        },
      },
    },
  },

  '&:first-of-type': {
    marginTop: 0,
  },

  '& > header': {
    fontFamily: '$Poppins',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& svg': {
      marginRight: '$8',
    },
    '& > h2': {
      flex: 3,
      fontSize: '$heading20',
      fontWeight: '$regular400',
      textTransform: 'capitalize',
      margin: 0,
    },
  },
});

interface IProps extends React.ComponentPropsWithoutRef<typeof StyledContentPanel> {
  children: React.ReactNode;
  headerIcon?: React.ReactNode;
  headerButton?: React.ReactNode;
  headerTitle?: string;
  color?: 'outline' | 'filled';
  headerBorder?: boolean;
  className?: string;
}

const ContentPanel = ({
  children,
  headerIcon,
  headerTitle,
  headerButton,
  headerBorder = false,
  color = 'outline',
  className,
}: IProps) => {
  return (
    <StyledContentPanel color={color} headerBorder={headerBorder} className={className}>
      {!!headerTitle && (
        <header>
          {headerIcon}
          <h2>{headerTitle}</h2>
          {headerButton}
        </header>
      )}
      {children}
    </StyledContentPanel>
  );
};

export default ContentPanel;
