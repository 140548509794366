import FeatherIcon from '@components/FeatherIcon';
import { ButtonColor } from '@components/v2/Button/Button';
import React, { PropsWithChildren } from 'react';
import { styled } from 'stitches.config';
import type * as Stitches from '@stitches/react';
import { Modal } from './Modal';
import { BaseButtonRow } from './ModalButtonRow';

import { BUTTON_ALIGNMENT } from './ModalButtonRow';

const ModalHeader = styled('h3', {
  fontSize: '$heading26',
  fontFamily: '$Nunito',
  fontWeight: '$semibold700',
  color: '$black',
  lineHeight: 'normal',
  margin: 0,
});

const Header = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: 24,
  gap: 8,
  '& button': {
    padding: 0,
    lineHeight: 1.5,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    '& svg': {
      color: '$grey300',
      marginBlockStart: 6,
    },
  },
});

type Props = {
  open: boolean;
  accessibleTitle: string;
  accessibleDescription: string;
  title: string;
  onCancel: () => void;
  onSubmit?: () => void;
  formId?: string;
  isSubmitting?: boolean;
  disabledSubmit?: boolean;
  closeLabel?: string;
  cancelId?: string;
  submitLabel?: string;
  submitId?: string;
  css?: Stitches.CSS;
};

const FormModal = ({
  open,
  onSubmit,
  onCancel,
  accessibleTitle,
  accessibleDescription,
  title,
  formId,
  isSubmitting,
  disabledSubmit,
  closeLabel = 'Cancel',
  submitLabel = 'Save',
  children,
  css,
  submitId,
  cancelId,
}: PropsWithChildren<Props>) => {
  return (
    <Modal
      open={open}
      onClose={onCancel}
      css={css}
      accessibilityDescription={accessibleDescription}
      accessibilityTitle={accessibleTitle}
    >
      <Header>
        <ModalHeader>{title}</ModalHeader>
        <button>
          <FeatherIcon name="X" onClick={onCancel} />
        </button>
      </Header>
      {children}
      <BaseButtonRow
        onClose={onCancel}
        closeLabel={closeLabel}
        submitLabel={submitLabel}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
        buttonColor={ButtonColor.PEACOCK}
        form={formId}
        disabled={disabledSubmit}
        submitId={submitId}
        cancelId={cancelId}
      />
    </Modal>
  );
};

export default FormModal;
