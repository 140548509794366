import { EMaxDurationUnit, IPayCalcItemStats } from '@types';
import {
  add,
  differenceInDays,
  differenceInCalendarDays,
  Duration,
  format as fnsFormat,
  max,
  parseISO,
} from 'date-fns';
import { format as timeZoneFormat, utcToZonedTime } from 'date-fns-tz';
import { calculateHoursAndMinutes } from 'utils';

import { ScheduleProcessor } from '../../components/PlanCalendar/ScheduleProcessor';

export const formatWithTimeZone = (date, fmt, tz) => timeZoneFormat(utcToZonedTime(date, tz), fmt, { timeZone: tz });

export const formatForDisplayWithTimezone = (
  date: string,
  format = 'yyyy-MM-dd',
  defaultDisplayStr = '',
  tz = 'UTC',
) => {
  if (!date) return defaultDisplayStr;
  return formatWithTimeZone(parseISO(date), format, tz);
};

export function formatDate(date: string, format = 'M/d/yyyy', addDuration: Duration = null) {
  if (!date) return 'N/A';
  let parsedDate = parseISO(date);
  if (parsedDate.toString() === 'Invalid Date') return 'N/A';
  if (addDuration && parsedDate) parsedDate = add(parsedDate, addDuration);
  return fnsFormat(parsedDate, format);
}

export function getLengthInWeeks(daysDiff: number, weekLength?: number) {
  weekLength = weekLength || 7; // we can count 'working weeks'.. 5 days is 1 week
  const diffWeeks = Math.floor(daysDiff / weekLength);
  const diffDays = daysDiff % weekLength;
  const workingWeek = weekLength !== 7 ? 'working ' : '';
  let desc = '';
  if (diffWeeks) {
    desc += `${diffWeeks || 0} ${workingWeek}week${diffWeeks > 1 ? 's' : ''}`;
  }
  if (diffWeeks && diffDays) {
    desc += `, `;
  }
  if (diffDays) {
    desc += getLengthInDays(diffDays);
  }
  return desc;
}

export function getLengthInDays(diffDays: number) {
  return `${diffDays} day${diffDays > 1 ? 's' : ''}`;
}
export function getUsedAmounts(stats: IPayCalcItemStats, unit: EMaxDurationUnit) {
  let maxAmount = null;
  let usedAmount = null;
  let availableAmount = null;
  if (stats?.maxDays) {
    if (unit === EMaxDurationUnit.DAY) {
      maxAmount = getLengthInDays(stats.maxDays);
      usedAmount = getLengthInDays(stats.usedDays);
      availableAmount = getLengthInDays(stats.maxDays - stats.usedDays);
    } else {
      maxAmount = getLengthInWeeks(stats.maxDays, stats.weekDays);
      usedAmount = getLengthInWeeks(stats.usedDays, stats.weekDays);
      availableAmount = getLengthInWeeks(stats.maxDays - stats.usedDays, stats.weekDays);
    }
  } else if (stats?.maxMinutes) {
    maxAmount = ScheduleProcessor.totalMinutesToHours(stats.maxMinutes);
    usedAmount = ScheduleProcessor.totalMinutesToHours(stats.usedMinutes);
    availableAmount = ScheduleProcessor.totalMinutesToHours(stats.maxMinutes - stats.usedMinutes);
  }

  // const unit = 'weeks';
  const usageAsString = `${usedAmount || 0} out of ${maxAmount || 0}`;
  return { maxAmount, usedAmount, usageAsString, availableAmount };
}

export function totalMinutesToHours(totalMinutes: number, formatUnit = true): string {
  const hours = Math.floor(totalMinutes / 60) || 0;
  const minutes = Math.floor(totalMinutes % 60) || 0;
  const hoursFormat = `${hours}${formatUnit ? 'h' : ''}`;
  return minutes > 0
    ? `${hoursFormat}${formatUnit ? ' ' : ':'}${String(minutes).padStart(2, '0')}${formatUnit ? 'm' : ''}`
    : hoursFormat;
}

export function getDifferenceDisplay(startDate, endDate, totalMinutes): string {
  // Note: The difference between two dates will be every day exclusive of the end date,
  // so we add 1 to get the total number of days within the date range.
  const totalDays = Math.round(differenceInDays(parseISO(endDate), parseISO(startDate))) + 1;
  if (totalMinutes < 60) {
    return `${totalMinutes} minutes`;
  }
  if (totalDays < 7) {
    return `${Math.round((totalMinutes / 60) * 10) / 10} hours`;
  }
  const weeks = Math.floor(totalDays / 7);
  const weekStr = `${weeks} week${weeks == 1 ? '' : 's'}`;
  const days = totalDays % 7;
  const daysStr = days > 0 ? `${days} day${days == 1 ? '' : 's'}` : '';
  return [weekStr, daysStr].filter((str) => str !== '').join(' ');
}

export function getDifferenceDisplayAbbr(startDate, endDate, totalMinutes): string {
  const totalDays = Math.round(differenceInDays(parseISO(endDate), parseISO(startDate))) + 1;
  if (totalMinutes < 60) {
    return `${totalMinutes}M`;
  }
  if (totalDays < 7) {
    const { hours, minutes } = calculateHoursAndMinutes(totalMinutes);

    return minutes === 0 ? `${hours}H` : `${hours}H ${minutes}M`;
  }
  const weeks = Math.floor(totalDays / 7);
  const weekStr = `${weeks}W`;
  const days = totalDays % 7;
  const daysStr = days > 0 ? `${days}D` : '';
  return [weekStr, daysStr].filter((str) => str !== '').join(' ');
}

export function generateUsedBenefitData(benefitData) {
  let workingDays = countWorkingDays(benefitData.fromField, benefitData.toField);
  let durationUnit = null;

  if (benefitData.maxDurationUnit === EMaxDurationUnit.DAY) {
    durationUnit = 'day';
  } else {
    durationUnit = 'week';
  }
  if (benefitData.maxDuration > 1) {
    durationUnit += 's';
  }

  const usedTimeString = getUsedLengthString(workingDays);
  const usageAsString = `${usedTimeString} out of ${Math.trunc(benefitData.maxDuration)} ${durationUnit}`;

  return usageAsString;
}

export function getUsedLengthString(workingDays: number) {
  const weekLength = 7;
  const diffWeeks = Math.floor(workingDays / weekLength);
  const diffDays = workingDays % weekLength;
  let desc = '';
  if (diffWeeks) {
    desc += `${diffWeeks || 0} week${diffWeeks > 1 ? 's' : ''}`;
  }
  if (diffWeeks && diffDays) {
    desc += `, `;
  }
  if (diffDays) {
    desc += getLengthInDays(diffDays);
  }
  return desc || 0;
}

export function countWorkingDays(startDate, endDate): number {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const diffDays = differenceInCalendarDays(end, start);

  return diffDays + 1;
}

export const checkDateOverlap = (index: number, startDate: string, endDate: string, watchDates) => {
  if (!watchDates) return false;
  return watchDates.some((dt, i) => {
    if (i === index) return false; // Skip the current field being edited
    const existingStart = new Date(dt.startDate);
    const existingEnd = new Date(dt.endDate);
    const newStart = new Date(startDate);
    const newEnd = new Date(endDate);

    // Check for overlap
    return (
      (newStart >= existingStart && newStart <= existingEnd) ||
      (newEnd >= existingStart && newEnd <= existingEnd) ||
      (newStart <= existingStart && newEnd >= existingEnd)
    );
  });
};

export const findMatchingDenial = (range) => {
  if (!range.denials || range.denials.length === 0) {
    return null;
  }
  const startDate = range.startDate;
  const endDate = range.endDate;
  return range.denials.find((denial) => startDate === denial.startDate && endDate === denial.endDate) || null;
};
