import { IPlanAdminVM, IPlanVM } from '@types';
import React, { createContext, useContext, useReducer } from 'react';

export enum EPlanContext {
  SET_PLAN = 'SET_PLAN',
  SET_PLANS = 'SET_PLANS',
  DISABLE_DRAWER_OUTSIDE_CLICK = 'DISABLE_DRAWER_OUTSIDE_CLICK',
  TOGGLE_PLAN_MODAL = 'TOGGLE_PLAN_MODAL',
}

export type Dispatch = (action) => void;
type State = {
  plan: IPlanAdminVM | IPlanVM;
  plans: IPlanAdminVM[] | IPlanVM[];
  disableDrawerOutsideClick: boolean;
  modalOpen: number | string | null;
};
type PlanProviderProps = { children: React.ReactNode };

const initialState = {
  plan: null,
  plans: [],
  disableDrawerOutsideClick: false,
  modalOpen: null,
};

export const PlanContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(null);

export function planReducer(state: State, action) {
  switch (action.type) {
    case EPlanContext.SET_PLAN: {
      return {
        ...state,
        plan: action.payload,
      };
    }
    case EPlanContext.SET_PLANS: {
      return {
        ...state,
        plans: action.payload,
      };
    }
    case EPlanContext.DISABLE_DRAWER_OUTSIDE_CLICK: {
      return {
        ...state,
        disableDrawerOutsideClick: action.payload,
      };
    }
    case EPlanContext.TOGGLE_PLAN_MODAL: {
      return {
        ...state,
        modalOpen: action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const PlanProvider = ({ children }: PlanProviderProps) => {
  const [state, dispatch] = useReducer(planReducer, initialState);
  const value = { state, dispatch };
  return <PlanContext.Provider value={value}>{children}</PlanContext.Provider>;
};

export const usePlan = () => {
  const context = useContext(PlanContext);
  if (!context) {
    throw new Error('usePlan must be used within a PlanProvider');
  }
  return context;
};

export default PlanProvider;
