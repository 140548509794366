import { Trigger } from '@radix-ui/react-dropdown-menu';
import React from 'react';

interface IProps {
  children?: React.ReactChild | React.ReactChild[] | JSX.Element | JSX.Element[] | string | [string];
}
/**
 * https://www.radix-ui.com/docs/primitives/components/dropdown-menu#trigger
 */
export const DropdownMenuTrigger = ({ children }: IProps) => {
  return (
    <Trigger asChild data-testid="dropdown-menu-trigger">
      {children}
    </Trigger>
  );
};
