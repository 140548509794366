import {
  DjangoList,
  IFileVM,
  ILeaveFormDataVM,
  ILeaveHRVM,
  IMessageVM,
  IPerLeaveBenefitUsage,
  IPlanVM,
  IProfileVM,
  IScheduleDaysVM,
  IStepVM,
  IUserLeaveVM,
} from '@types';
import { IDocument } from '@constants/types';

import { BASE_API_PATH } from './constants';
import QueryKeys from './queryKeys';
import { mutate, mutateBatch, query } from './request-lib';

const EE = {
  Documents: {
    create: (options = {}) => mutate('post', `${BASE_API_PATH}/documents/documents/`, { ...options, isUpload: true }),
    getMany: (params = {}, options = {}) =>
      query<DjangoList<IDocument>>('get', `${BASE_API_PATH}/documents/documents/`, params, options),
    update: (options = {}) =>
      mutate('patch', `${BASE_API_PATH}/documents/documents/:documentId/`, {
        ...options,
        isUpload: true,
        retry: false,
      }),
  },
  DocumentRequest: {
    getMany: (params = {}, options = {}) =>
      query<DjangoList<IDocument>>('get', `${BASE_API_PATH}/documents/document-requests/`, params, options),
  },
  File: {
    getMany: (params = {}, options = {}) =>
      query<DjangoList<IFileVM>>('get', `${BASE_API_PATH}/legacy/files/`, params, options),
    getOne: (params = {}, options = {}) =>
      query<IFileVM>('get', `${BASE_API_PATH}/legacy/files/:fileId/`, params, options),
  },
  Leave: {
    create: (options = {}) => mutate<ILeaveHRVM>('post', `${BASE_API_PATH}/legacy/leaves/`, options),
    getMany: (params = {}, options = {}) =>
      query<DjangoList<IUserLeaveVM>>('get', `${BASE_API_PATH}/legacy/leaves/`, params, options),
    getOne: (params = {}, options = {}) =>
      query<IUserLeaveVM>('get', `${BASE_API_PATH}/legacy/leaves/:leaveId/`, params, options),
    update: (options = {}) => mutate<ILeaveHRVM>('put', `${BASE_API_PATH}/legacy/leaves/:leaveId/`, options),
    submit: (options = {}) => mutate('post', `${BASE_API_PATH}/legacy/leaves/:leaveId/`, options),
    Benefit: {
      getMany: (params = {}, options = {}) =>
        query<IPerLeaveBenefitUsage[]>('get', `${BASE_API_PATH}/legacy/leaves/:leaveId/benefits/`, params, options),
    },
    Form: {
      getNew: (params = {}, options = {}) =>
        query<ILeaveFormDataVM>('get', `${BASE_API_PATH}/legacy/leaves/form/`, params, options),
      getOne: (params = {}, options = {}) =>
        query<ILeaveFormDataVM>('get', `${BASE_API_PATH}/legacy/leaves/:leaveId/form/`, params, options),
    },
    HistoricalProfile: {
      getOne: (params = {}, options = {}) =>
        query<IProfileVM>('get', `${BASE_API_PATH}/legacy/leaves/:leaveId/historical-profile/`, params, options),
    },
    Plan: {
      getOne: (params = {}, options = {}) =>
        query<IPlanVM>('get', `${BASE_API_PATH}/legacy/leaves/:leaveId/plan/`, params, options),
    },
  },
  Notification: {
    getMany: (params = {}, options = {}) =>
      query<DjangoList<IMessageVM>>('get', `${BASE_API_PATH}/notifications/me/`, params, options),
    updateOne: (options = {}) => mutate('patch', `${BASE_API_PATH}/notifications/me/:messageId/`, options),
    Batch: {
      updateMany: (options = {}) => mutateBatch('patch', `${BASE_API_PATH}/notifications/me/batch/`, options),
    },
  },
  Plan: {
    getMany: (params = {}, options = {}) =>
      query<DjangoList<IPlanVM>>('get', `${BASE_API_PATH}/legacy/plans/`, params, options),
    getOne: (params = {}, options = {}) =>
      query<IPlanVM>('get', `${BASE_API_PATH}/legacy/plans/:planId/`, params, options, {}, QueryKeys.EePlanOne),
  },
  // Note, these scheduledays endpoints are actually accessed by all end User types
  ScheduleDay: {
    create: (options = {}) => mutate('post', `${BASE_API_PATH}/legacy/scheduledays/`, options),
    partialUpdate: (options = {}) => mutate('patch', `${BASE_API_PATH}/legacy/scheduledays/:rangeId/`, options),
    update: (options = {}) => mutate('put', `${BASE_API_PATH}/legacy/scheduledays/:scheduleDayId/`, options),
    delete: (options = {}) => mutate('delete', `${BASE_API_PATH}/legacy/scheduledays/:scheduleDayId/`, options),
    getMany: (params = {}, options = {}) =>
      query<DjangoList<IScheduleDaysVM>>('get', `${BASE_API_PATH}/legacy/scheduledays/`, params, options),
    getPendingWeeks: (params = {}, options = {}) =>
      query<[]>('get', `${BASE_API_PATH}/legacy/scheduledays/pending-weeks/:planId/`, params, options),
    bulkUpdate: (options = {}) => mutate('post', `${BASE_API_PATH}/legacy/scheduledays/bulk-update/`, options),
  },
  // Note, these tracking endpoints are actually accessed by all end User types
  Tracking: {
    getUsage: (params = {}, options = {}) => query<any[]>('get', `${BASE_API_PATH}/tracking/usage/`, params, options),
  },
  User: {
    Profile: {
      getOne: (params = {}, options = {}) =>
        query<IProfileVM>('get', `${BASE_API_PATH}/legacy/users/:userId/profile/`, params, options),
    },
  },
  Task: {
    create: (options = {}) => mutate('post', `${BASE_API_PATH}/legacy/tasks/`, options),
  },
  Policy: {
    getAccessiblePolicyInfo: (params = {}, options = {}) =>
      query<Record<string, unknown>>('get', `${BASE_API_PATH}/policy/viewable/`, params, options),
  },
  Steps: {
    getMany: (params = {}, options = {}) =>
      query<DjangoList<IStepVM>>('get', `${BASE_API_PATH}/legacy/steps/`, params, options),
    getOne: (params = {}, options = {}) =>
      query<IStepVM>('get', `${BASE_API_PATH}/legacy/steps/:stepId/`, params, options),
    toggleDone: (options = {}) => mutate('patch', `${BASE_API_PATH}/legacy/steps/:stepId/done/`, options),
  },
};

export default EE;
