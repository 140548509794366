import { Trigger } from '@radix-ui/react-accordion';
import React from 'react';
import { styled } from 'stitches.config';

/**
 * https://www.radix-ui.com/docs/primitives/components/accordion#trigger
 */
interface IProps {
  className?: string;
  children?: React.ReactChild | React.ReactChild[] | JSX.Element | JSX.Element[] | string | [string];
}

const UnstyledButton = styled('button', {
  background: 'none',
  border: 'none',
  padding: 0,
  margin: 0,
  color: '$black',
  fontSize: '1rem',
  fontWeight: '$regular400',
  lineHeight: '$24',
  cursor: 'pointer',
  maxBlockSize: '100%',
  width: '100%',
  textAlign: 'left',
  '&:focus': {
    border: 'none',
  },
});

export const AccordionTrigger = ({ className, children }: IProps) => {
  return (
    <Trigger asChild className={className} data-testid="accordion-trigger">
      <UnstyledButton>{children}</UnstyledButton>
    </Trigger>
  );
};
