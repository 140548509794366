import { Content } from '@radix-ui/react-dialog';
import type * as Stitches from '@stitches/react';
import React from 'react';
import { styled } from 'stitches.config';
/*
ref: https://www.radix-ui.com/docs/primitives/components/dialog#content
*/

const ModalContent = styled(Content, {
  background: '$white',
  maxBlockSize: '100vh',
  borderRadius: '4px',
  overflowY: 'auto',
  position: 'absolute',
  inset: '0',
  width: 'fit-content',
  height: 'fit-content',
  maxHeight: '94vh',
  transform: 'translate(-50%, -50%)',
  left: '50%',
  top: '50%',
  boxShadow:
    '0px 1px 8px 0px rgba(0, 0, 0, 0.20), 0px 3px 3px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14)',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '@lgMax': {
    width: 'calc(100vw - 2rem)',
  },
});

interface IProps {
  children: React.ReactNode;
  onEscapeKeyDown: () => void;
  className: string;
  onPointerDownOutside: () => void;
  preventAutoFocus?: boolean;
  css?: Stitches.CSS;
}
const ModalContentWithProps = (
  { children, onEscapeKeyDown, onPointerDownOutside, className, css, preventAutoFocus }: IProps,
  ref: React.MutableRefObject<HTMLDivElement>,
) => {
  return (
    <ModalContent
      aria-modal="true"
      ref={ref}
      css={css}
      onEscapeKeyDown={onEscapeKeyDown}
      onPointerDownOutside={onPointerDownOutside}
      className={className}
      onOpenAutoFocus={(event) => {
        if (preventAutoFocus) {
          event.preventDefault();
        }
      }}
    >
      {children}
    </ModalContent>
  );
};

export default React.forwardRef<HTMLDivElement, IProps>(ModalContentWithProps);
