import FeatherIcon, { IconName } from '@components/FeatherIcon';
import React from 'react';

import styles from './PageTitle.module.scss';

interface PageTitleProps {
  title: string;
  children?: React.ReactChild | React.ReactChild[];
  icon?: IconName;
}
export const PageTitle = ({ title = '', icon, children }: PageTitleProps) => (
  <div className={styles.wrapper}>
    {icon && <FeatherIcon name={icon} />}
    <h2 className={styles.title}>{title}</h2>
    {children ? <div>{children}</div> : null}
  </div>
);
