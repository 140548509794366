import KeyTrap from '@components/KeyTrap';
import cx from 'classnames';
import React from 'react';
import OutsideClick from 'react-outside-click-handler';
import { ScrollLocky } from 'react-scroll-locky';

import styles from './Modal.module.scss';

interface IProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  closeOnKeys?: string[];
  wrapClassName?: string;
  containerClassName?: string;
  className?: string;
  containerStyles?: { [key: string]: string };
}

const Modal = (
  {
    onClose = () => {},
    children,
    closeOnKeys = ['esc'],
    open,
    wrapClassName = '',
    containerClassName = '',
    className = '',
    containerStyles = {},
  }: IProps,
  ref,
) => {
  if (!open) {
    return null;
  }
  return (
    // @ts-ignore
    <ScrollLocky>
      <div
        className={cx(styles.wrap, wrapClassName)}
        role="dialog"
        tabIndex={-1}
        aria-labelledby="modal"
        aria-live="assertive"
      >
        <div className={cx(styles.container, containerClassName)} role="document">
          <OutsideClick onOutsideClick={onClose}>
            <div ref={ref} style={containerStyles} className={className}>
              {children}
            </div>
          </OutsideClick>
          {/* @ts-ignore */}
          <KeyTrap on={closeOnKeys} handleEvent={onClose} />
        </div>
      </div>
    </ScrollLocky>
  );
};

export default React.forwardRef(Modal);
