import cx from 'classnames';
import React from 'react';

import styles from './RadioButton.module.scss';
import { EDisplayAs } from './RadioButtons';

interface IProps {
  label?: string;
  className?: string;
  labelClassName?: string;
  checked?: boolean;
  id?: string;
  name?: string;
  disabled?: boolean;
  value?: string | number;
  onValueChange?: (value) => void;
  ref: React.Ref<HTMLInputElement>;
  displayAs?: EDisplayAs;
}

const RadioButtonWithRef = (
  { id, label, className, labelClassName, checked, name, value, onValueChange, displayAs, disabled, ...rest }: IProps,
  ref,
) => {
  return (
    <div
      className={cx(styles.radioWrapper, {
        [styles.recButton]: displayAs === EDisplayAs.REC_BUTTON,
      })}
    >
      <input
        {...rest}
        id={id}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        className={cx(styles.radioButton, className)}
        onChange={onValueChange}
        ref={ref}
        disabled={disabled}
        aria-labelledby={id}
        aria-hidden={displayAs === EDisplayAs.REC_BUTTON}
      />
      <label className={cx(styles.label, labelClassName)} htmlFor={id}>
        {' '}
        {label}
      </label>
    </div>
  );
};

const RadioButton = React.forwardRef<HTMLInputElement, IProps>(RadioButtonWithRef);
export default RadioButton;
