import { Content } from '@radix-ui/react-accordion';
import React from 'react';

/**
 * https://www.radix-ui.com/docs/primitives/components/accordion#content
 */
interface IProps {
  className?: string;
  children?: React.ReactChild | React.ReactChild[];
}

export const AccordionContent = ({ className, children }: IProps) => {
  return (
    <Content className={className} data-testid="accordion-content">
      {children}
    </Content>
  );
};
