import { combineReducers } from 'redux';
import { put, race, take, takeEvery } from 'redux-saga/effects';

export const PROMPT = 'PROMPT';
export const PROMPT_OK = 'PROMPT_OK';
export const PROMPT_CANCEL = 'PROMPT_CANCEL';
export const PROMPT_CLOSE = 'PROMPT_CLOSE';
export const PROMPT_ERROR = 'PROMPT_ERROR';

export default combineReducers({
  info: promptReducer,
  error: errorReducer,
  loading: loadingReducer,
});

function promptReducer(state = null, action) {
  switch (action.type) {
    case PROMPT:
      return action.payload;
    case PROMPT_CLOSE:
      return null;
    default:
      return state;
  }
}

function loadingReducer(state = null, action) {
  switch (action.type) {
    case PROMPT_OK:
      return true;
    case PROMPT_ERROR:
    case PROMPT_CLOSE:
      return false;
    default:
      return state;
  }
}

function errorReducer(state = null, action) {
  switch (action.type) {
    case PROMPT_OK:
    case PROMPT_CLOSE:
      return null;
    case PROMPT_ERROR:
      return action.error;
    default:
      return state;
  }
}

export function* rootSaga() {
  yield takeEvery(PROMPT, checkPrompt);
}

export function* checkPrompt({ resolve, payload }) {
  const { ok } = yield race({
    ok: take(PROMPT_OK),
    cancel: take(PROMPT_CANCEL),
  });
  resolve(!!ok);
  if (payload && payload.showLoading) {
    return;
  }
  yield put(closePromptAction());
}

export const openPromptAction = ({ resolve, ...payload }) => ({
  resolve,
  type: PROMPT,
  payload,
});

export const okPromptAction = () => ({
  type: PROMPT_OK,
});

export const cancelPromptAction = () => ({
  type: PROMPT_CANCEL,
});

export const closePromptAction = () => ({
  type: PROMPT_CLOSE,
});

export const showPromptErrorAction = (error) => ({
  type: PROMPT_ERROR,
  error,
});
