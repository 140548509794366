import FeatherIcon from '@components/FeatherIcon';
import { Chip } from '@components/v2';
import {
  IconContainer,
  InputContainer,
  ListItem,
  MainContainer,
  Menu,
  SelectContainer,
} from '@components/v2/StyleGuide/ColorGuide/components/DropdownStyles';
import { Text } from '@components/v2/StyleGuide/ColorGuide/components/Text';
import type * as Stitches from '@stitches/react';
import { ISelectOption } from '@constants/types';
import { useMultipleSelection, useSelect } from 'downshift';
import React, { useEffect } from 'react';

type Props = {
  items: ISelectOption[];
  label?: string;
  css?: Stitches.CSS;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  onSelectedItemChange: (items: ISelectOption[]) => void;
  defaultSelectedItems?: ISelectOption[];
};

const MultiSelect = ({
  label,
  items,
  css,
  placeholder,
  error,
  onSelectedItemChange,
  defaultSelectedItems,
  disabled = false,
  ...rest
}: Props) => {
  const { getSelectedItemProps, getDropdownProps, selectedItems, setSelectedItems } = useMultipleSelection();

  const handleSelect = (items) => {
    setSelectedItems(items);
    onSelectedItemChange(items);
  };
  const { isOpen, getToggleButtonProps, getLabelProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    items,
    selectedItem: null,
    defaultHighlightedIndex: 0,
    onSelectedItemChange: ({ selectedItem }) => {
      if (!disabled) {
        const sameItem = (selectedItems as ISelectOption[]).find((item) => item.value === selectedItem?.value);
        if (sameItem) {
          const filtered = (selectedItems as ISelectOption[]).filter((item) => item.value !== selectedItem.value);
          return handleSelect(filtered);
        }
        const newItems = [...(selectedItems as ISelectOption[]), selectedItem];
        handleSelect(newItems);
      }
    },
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
            highlightedIndex: 0, // with the first option highlighted.
          };
      }
      return changes;
    },
  });

  useEffect(() => {
    if (defaultSelectedItems) {
      setSelectedItems(defaultSelectedItems);
    }
  }, [defaultSelectedItems, setSelectedItems]);

  return (
    <MainContainer css={css}>
      {label && (
        <Text css={css} TextType="inputLabel" as="label" {...getLabelProps()}>
          {label}
        </Text>
      )}
      <InputContainer
        {...rest}
        disabled={disabled}
        label={!!label}
        css={css}
        {...getToggleButtonProps(getDropdownProps({ preventKeyAction: isOpen, type: 'button' }))}
      >
        <SelectContainer disabled={disabled} placeholder={placeholder}>
          {selectedItems.map((selectedItemToRender, index) => {
            return (
              <span
                key={`selected-item-${index}`}
                data-testid={`selected-item-${index}`}
                {...getSelectedItemProps({ selectedItem: selectedItemToRender, index })}
              >
                <Chip
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!disabled) {
                      const filtered = selectedItems.filter((item) => item !== selectedItemToRender);
                      handleSelect(filtered);
                    }
                  }}
                >
                  {(selectedItemToRender as ISelectOption).label}
                </Chip>
              </span>
            );
          })}
        </SelectContainer>
        <IconContainer>
          <FeatherIcon name="ChevronDown" height={20} width={20} className="arrow" />
        </IconContainer>
      </InputContainer>
      {error && <Text TextType="inputError">{error}</Text>}
      <Menu {...getMenuProps()} hidden={!(isOpen && items.length)} css={css}>
        {isOpen &&
          items.map((item, index) => {
            const isSelected = (selectedItems as ISelectOption[]).find((selected) => selected.value === item.value);
            return (
              <ListItem
                css={css}
                highlighted={highlightedIndex === index}
                selected={!!isSelected}
                key={`${item}${index}`}
                {...getItemProps({ item, index })}
              >
                {item.label}
                {isSelected && <FeatherIcon name="Check" height={16} width={16} className="checkmark" />}
              </ListItem>
            );
          })}
      </Menu>
    </MainContainer>
  );
};

export default MultiSelect;
