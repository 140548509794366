import FeatherIcon from '@components/FeatherIcon';
import React from 'react';
import { styled } from 'stitches.config';

import { DropdownMenuContent, DropdownMenuPortal, DropdownMenuRoot, DropdownMenuTrigger } from '../';

const StyledTrigger = styled('button', {
  position: 'relative',
  border: 'none',
  background: 'none',
  padding: '0 $2',
  cursor: 'pointer',
  pointerEvents: 'auto !important',
  '&:hover, &:focus, &:active': {
    background: '$grey100',
  },
});

interface IProps extends React.ComponentPropsWithoutRef<typeof DropdownMenuRoot> {
  testid?: string;
}

const VerticalDropdownMenu = ({ testid = 'vertical-dropdown-menu', children }: IProps) => {
  return (
    <DropdownMenuRoot id={testid}>
      <DropdownMenuTrigger>
        <StyledTrigger data-testid={`${testid}-trigger`}>
          <FeatherIcon style={{ height: '100%', lineHeight: 0 }} name="MoreVertical" />
        </StyledTrigger>
      </DropdownMenuTrigger>
      <DropdownMenuPortal>
        <DropdownMenuContent data-testid={`${testid}-content`}>{children}</DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenuRoot>
  );
};

export default VerticalDropdownMenu;
