import type * as Stitches from '@stitches/react';
import React from 'react';
import { styled } from 'stitches.config';

const StyledDL = styled('dl', {
  margin: '0',
  padding: '0',
  fontSize: '$body16',
  lineHeight: '$24',
  width: '100%',
});

const StyledDT = styled('dt', {
  fontWeight: '$semibold700',
});

const StyledDD = styled('dd', {
  margin: 0,
  marginTop: 8,
  display: 'flex',
  '& > *': {
    flex: 'auto',
  },
  '& > *:last-child': {
    justifyContent: 'flex-end',
    flex: 'none',
  },
  '& + dt': {
    marginTop: 16,
  },

  variants: {
    weight: {
      light: {
        color: '$grey300',
      },
    },
  },
});

interface IProps {
  children: React.ReactNode;
  weight?: 'light';
  css?: Stitches.CSS;
}
const DefList = ({ children, ...rest }: IProps) => <StyledDL {...rest}>{children}</StyledDL>;
const DefTitle = ({ children, ...rest }: IProps) => <StyledDT {...rest}>{children}</StyledDT>;
const DefDescription = ({ children, ...rest }: IProps) => <StyledDD {...rest}>{children}</StyledDD>;

export { DefList, DefTitle, DefDescription, StyledDL, StyledDT, StyledDD };
