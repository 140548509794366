import { captureException } from '@sentry/nextjs';

export const runWithErrorLogging = (initializer, context) => {
  try {
    initializer();
  } catch (e) {
    captureException(e, context);
    console.error(`Error occurred in ${context}:`, e);
  }
};
