import FeatherIcon, { IconName } from '@components/FeatherIcon';
import { coral, grass, pumpkinPrimary, secondarySky } from '@constants/colors';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';

import styles from './Banner.module.scss';

export enum EBannerStatus {
  INFO = 'info',
  INFO_WARN = 'infoWarn',
  SUCCESS = 'success',
  WARN = 'warn',
  ERROR = 'error',
}

type IProps = {
  title?: string;
  body: string | Element | React.ReactNode;
  link?: string;
  linkText?: string;
  status: EBannerStatus;
  items?: string[];
  itemsPreviewAmount?: number;
  dismissable?: boolean;
  onDismiss?: () => void;
  action?: React.ReactElement | null;
};

const statusStyles = {
  [EBannerStatus.INFO]: {
    icon: 'Info',
    iconColor: secondarySky,
    style: styles.info,
    itemStatusTypeText: 'details',
  },
  [EBannerStatus.INFO_WARN]: {
    icon: 'Info',
    iconColor: pumpkinPrimary,
    style: styles.pumpkin,
    itemStatusTypeText: 'warnings',
  },
  [EBannerStatus.SUCCESS]: {
    icon: 'CheckCircle',
    iconColor: grass,
    style: styles.success,
    itemStatusTypeText: 'details',
  },
  [EBannerStatus.WARN]: {
    icon: 'Info',
    iconColor: pumpkinPrimary,
    style: styles.pumpkin,
    itemStatusTypeText: 'warnings',
  },
  [EBannerStatus.ERROR]: {
    icon: 'AlertTriangle',
    iconColor: coral,
    style: styles.error,
    itemStatusTypeText: 'errors',
  },
};

const Banner = ({
  title = '',
  body,
  link = '',
  linkText = '',
  status,
  items,
  itemsPreviewAmount,
  dismissable = false,
  onDismiss,
  action = null,
}: IProps) => {
  const [itemsToShow, setItemsToShow] = useState([]);
  const [showMoreLessText, setShowMoreLessText] = useState('');

  useEffect(() => {
    if (items) {
      setItemsToShow(items.slice(0, itemsPreviewAmount));
      setShowMoreLessText(
        items.length > itemsPreviewAmount ? `View all ${items.length} ${statusStyles[status].itemStatusTypeText}` : '',
      );
    }
  }, [items, itemsPreviewAmount, status]);

  const toggleItems = () => {
    if (itemsToShow.length < items.length) {
      setItemsToShow(items);
      setShowMoreLessText(`View fewer ${statusStyles[status].itemStatusTypeText}`);
    } else {
      setItemsToShow(items.slice(0, itemsPreviewAmount));
      setShowMoreLessText(`View all ${items.length} ${statusStyles[status].itemStatusTypeText}`);
    }
  };

  return (
    <div className={cx(styles.box, statusStyles[status].style)}>
      <FeatherIcon name={statusStyles[status].icon as IconName} color={statusStyles[status].iconColor} />
      <div className={styles.boxDescription}>
        <div className={styles.descriptionTitle}>{title}</div>
        <div>
          <>
            {body}{' '}
            {link && (
              <a href={link} target="_blank" className={styles.link} rel="noreferrer">
                {linkText}
              </a>
            )}
          </>
        </div>
        {itemsToShow.length ? (
          <div className={styles.itemsList}>
            <ul>
              {itemsToShow.map((item, idx) => {
                return (
                  <li key={idx}>
                    <span>{item}</span>
                  </li>
                );
              })}
            </ul>
            <div onClick={toggleItems}>
              <span className={styles.showMoreLessText}>{showMoreLessText}</span>
            </div>
          </div>
        ) : null}
      </div>
      {dismissable && (
        <FeatherIcon
          onClick={() => {
            if (onDismiss) onDismiss();
          }}
          className={styles.dismiss}
          name="X"
        />
      )}
      {action && <div className={styles.boxActions}>{action}</div>}
    </div>
  );
};

const ArchivedPlanBanner = () => {
  return (
    <Banner
      title="This plan is archived"
      body="If you have questions, please reach out to Tilt customer success."
      status={EBannerStatus.INFO}
    />
  );
};

export default Banner;
export { ArchivedPlanBanner };
