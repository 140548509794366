import FeatherIcon from '@components/FeatherIcon';
import React from 'react';
import { toast, ToastBar, Toaster } from 'react-hot-toast';
import { styled } from 'stitches.config';

const Wrapper = styled('button', {
  border: 'none',
  outline: 'none',
  padding: 0,
  margin: 0,
  cursor: 'pointer',
  background: 'transparent',
  textAlign: 'left',
});

const CircleIcon = styled(FeatherIcon, {
  color: '$secondaryGrass',
  minWidth: 24,
  minHeight: 24,
});

const AlertIcon = styled(FeatherIcon, {
  color: '$primaryCoral',
  minWidth: 24,
  minHeight: 24,
});

const MessageContainer = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  padding: 16,
  gap: 16,
  '& .header': {
    fontSize: '$body16',
    fontWeight: '$semibold700',
    lineHeight: 1.5,
    margin: 0,
  },
  '& .message': {
    fontSize: '$body16',
    lineHeight: 1.5,
    fontFamily: '$Nunito',
    fontWeight: '$regular400',
    color: '$grey400',
    margin: 0,
    '& > div': {
      margin: 0,
      justifyContent: 'flex-start',
    },
  },
});

const Toast = () => {
  return (
    <Toaster
      toastOptions={{
        position: 'top-center',
        duration: 4000,
        style: {
          // We defined these styles here but still use ToastBar below because
          // we don't want to defined ALL of the styles...
          background: '#fff',
          borderRadius: '4px',
          width: 400,
          maxWidth: 400,
          boxShadow:
            '0px 1px 8px 0px rgba(0, 0, 0, 0.20), 0px 3px 3px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14)',
        },
        success: {
          duration: null,
          style: {
            borderInlineStart: '10px solid #38976A',
          },
          icon: <CircleIcon name="CheckCircle" size={24} />,
        },
        error: {
          duration: 4000,
          style: {
            borderInlineStart: '10px solid #E0311A',
          },
          icon: <AlertIcon name="AlertTriangle" size={24} />,
        },
        loading: {
          style: {
            borderInlineStart: '10px solid $secondarySky',
          },
        },
      }}
    >
      {(t) => {
        return (
          <Wrapper type="button" onClick={() => toast.dismiss(t.id)} data-testid="toast-button">
            <ToastBar style={{ padding: 0 }} toast={t}>
              {({ icon, message }) => (
                <MessageContainer>
                  {icon}
                  <div>
                    <p className="header">
                      {t.type === 'success' ? 'Success' : t.type === 'error' ? 'Error' : 'Working'}
                    </p>
                    <div className="message">{message}</div>
                  </div>
                </MessageContainer>
              )}
            </ToastBar>
          </Wrapper>
        );
      }}
    </Toaster>
  );
};

export default Toast;
