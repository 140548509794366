import { BenefitStatusInfo, EBenefitTrackingMethod, EProrationMethod, IBenefitDenial, IDeniedTime } from '@types';

export interface IPerLeaveBenefitDateRange {
  startDate: string;
  endDate: string;
  paycalcItemId?: number;
  stats: {
    maxDays: number;
    usedDays: number;
    maxMinutes: number;
    usedMinutes: number;
    weekDays: number;
  };
  status: BenefitStatusInfo;
  denials: IBenefitDenial[];
  automated: boolean;
}

export interface IBenefitMetadataUsage {
  asOfDate: string;
  usage: {
    attemptedMinutes: number;
    scheduledMinutes: number;
    bankUsed: number;
    percentageUsed: number;
    totalMinuteBalance: number;
  };
  totalMinutes?: number;
  trackingStartDate: string; //iso string
  trackingEndDate: string; //iso string
}

export interface IBenefitUsage {
  name: string;
  uuid: string;
  leaveYearRefresh: string; //iso string
  currentYear?: IBenefitMetadataUsage;
  previousYear?: IBenefitMetadataUsage;
  nextYear?: IBenefitMetadataUsage;
  trackingMethod?: EBenefitTrackingMethod;
}

export enum EBenefitTimeUnit {
  MINUTES = 'MINUTES',
  DAYS = 'DAYS',
}

export interface IPerLeaveBenefitUsage {
  name: string;
  uuid: string;
  result?: number;
  documentClass: number | null;
  needsManualApproval: boolean;
  automated: boolean;
  dateRanges: IPerLeaveBenefitDateRange[];
  stats: {
    timeUnit?: EBenefitTimeUnit;
    totalAllocated: number;
    totalConsumed: number;
    approvedConsumed?: number;
    pendingConsumed?: number;
  };
  denials?: IDeniedTime[];
  jobProtection: boolean;
  paid: boolean;
  waitingPeriod: number;
  description: string;
  status: BenefitStatusInfo;
  asOfDate: string; // iso string
  benefitsTrackingMetadata?: IBenefitUsage;
  prorationMethod?: EProrationMethod;
}
