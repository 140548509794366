import Loading from '@components/Loading';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import useOnClickOutside from 'hooks/useOnOutsideClick';
import React, { useRef } from 'react';

import styles from './AlertDialog.module.scss';

interface IProps {
  title: string | React.ReactElement;
  description: string | React.ReactElement;
  onClose: () => void;
  onCancel?: (args?: unknown) => void;
  onAction?: (args?: unknown) => void;
  actionLabel?: string | React.ReactElement;
  cancelLabel?: string;
  loading?: boolean;
  useWarningColors?: boolean;
}

const AlertDialog = ({
  title,
  description,
  onClose,
  onAction,
  onCancel,
  actionLabel,
  cancelLabel,
  loading,
  useWarningColors = true,
}: IProps) => {
  const ref = useRef();

  useOnClickOutside(ref, () => onClose());
  return (
    <div className={styles.alertDialog}>
      <AlertDialogPrimitive.Root defaultOpen>
        <AlertDialogPrimitive.Overlay forceMount className={styles.overlay} />
        <AlertDialogPrimitive.Content forceMount onEscapeKeyDown={onClose} ref={ref} className={styles.content}>
          <div className={styles.description}>
            <AlertDialogPrimitive.Title>{title}</AlertDialogPrimitive.Title>
            <AlertDialogPrimitive.Description>{description}</AlertDialogPrimitive.Description>
          </div>
          <div className={styles.actions}>
            {onCancel && (
              <AlertDialogPrimitive.Cancel
                onClick={onCancel}
                className={styles.cancelButton}
                data-testid="alert-dialog-cancel-button"
              >
                {cancelLabel}
              </AlertDialogPrimitive.Cancel>
            )}
            {onAction && (
              <AlertDialogPrimitive.Action
                onClick={onAction}
                className={useWarningColors ? styles.actionButton : styles.safeActionButton}
                data-testid="alert-dialog-action-button"
              >
                {!loading && actionLabel}
                {loading && (
                  <div className={styles.loadingWrap}>
                    <Loading />
                  </div>
                )}
              </AlertDialogPrimitive.Action>
            )}
          </div>
        </AlertDialogPrimitive.Content>
      </AlertDialogPrimitive.Root>
    </div>
  );
};

export default AlertDialog;
