import {
  EPaycalcItemLength,
  EPayPeriodLength,
  EProrationMethod,
  EScheduleType,
  ILeaveHRVM,
  IPayCalcItemVM,
} from '@types';
import moment from 'moment';

// @NOTE - this is shared by FE and BE, don't put any specific dependency like entities or FE libraries
// TODO TRAC-251: transition from/to into fromField to toField
export interface ScheduleRange {
  to: string;
  from: string;
  title: string;
  notes: string;
  type: EScheduleType;
  minutesPerDayMask?: number[];
  totalMinutes?: number;
}
const defaultWorkSchedule = {
  from: '2000-01-01',
  to: '2050-01-01',
  type: EScheduleType.WORK_REGULAR,
  minutesPerDayMask: [0, 480, 480, 480, 480, 480, 0],
};

export interface transformPayPerPeriodParams {
  payPerPeriod: number;
  payPercentage: number;
  fromLength: EPayPeriodLength;
  toLength: EPaycalcItemLength;
}

export class ScheduleProcessor {
  rangesData;
  constructor() {}

  public calculateTotalMinutesFromRange(range: ScheduleRange): number {
    return ScheduleProcessor.calculateTotalMinutesFromRange(range);
  }
  public static calculateTotalMinutesFromRange(range: ScheduleRange): number {
    const length = moment(range.to).diff(range.from, 'day', false) + 1;
    return this.calculateTotalMinutesFromMask(moment(range.from), length, range.minutesPerDayMask);
  }

  public transformPayPerPeriod(params: transformPayPerPeriodParams) {
    return this._transformPayPerPeriodRaw(params).toFixed(2);
  }

  public _transformPayPerPeriodRaw(params: transformPayPerPeriodParams) {
    const { payPerPeriod, payPercentage, fromLength, toLength } = params;
    const amount = payPerPeriod * payPercentage;

    const getWeeklySalary = (fromLength, amount) => {
      if (fromLength === EPayPeriodLength.WEEKLY) {
        return amount;
      } else if (fromLength === EPayPeriodLength.BIWEEKLY) {
        return amount / 2;
      } else if (fromLength === EPayPeriodLength.MONTHLY) {
        return (amount * 12) / 52;
      } else if (fromLength === EPayPeriodLength.SEMIMONTHLY) {
        return (amount * 24) / 52;
      }
    };

    let transformedPay = 0;

    if (toLength === EPaycalcItemLength.PAY_PERIOD) {
      transformedPay = amount;
    } else if (toLength === EPaycalcItemLength.WEEKLY) {
      transformedPay = getWeeklySalary(fromLength, amount);
    } else if (toLength === EPaycalcItemLength.CALENDAR_DAY) {
      transformedPay = getWeeklySalary(fromLength, amount) / 7; // (for pay-calc it'll be salary * days-covered/daysInPeriod)
    } else if (toLength === EPaycalcItemLength.WORKING_DAY) {
      transformedPay = getWeeklySalary(fromLength, amount) / 5; // (for pay-calc it'll be salary * workingDays/WorkDaysInPeriod)
    } else if (toLength === EPaycalcItemLength.MONTHLY) {
      if (fromLength === EPayPeriodLength.WEEKLY) {
        transformedPay = (amount * 52) / 12; // yearly -> monthly
      } else if (fromLength === EPayPeriodLength.BIWEEKLY) {
        transformedPay = (amount * 26) / 12; // yearly -> monthly
      } else if (fromLength === EPayPeriodLength.MONTHLY) {
        transformedPay = amount;
      } else if (fromLength === EPayPeriodLength.SEMIMONTHLY) {
        transformedPay = amount * 2;
      }
    }
    return transformedPay / 100;
  }

  public static calculateTotalMinutesFromMask(from, length, minutesPerDayMask): number {
    // there's some math tricks here. we calculate how many 'mondays, tuesdays.. ' are in the range, and multiply by the mask
    const fullWeeks = Math.floor(length / 7);
    const fullWeekMinutes = minutesPerDayMask.reduce((prev, curr) => prev + curr, 0);
    let totalMinutes = fullWeeks * fullWeekMinutes;
    const fromDay = from.weekday(); // Sunday=0, Sat=6
    const nonFullWeekDays = length % 7;
    for (let i = 0; i < nonFullWeekDays; i++) {
      totalMinutes += minutesPerDayMask[(fromDay + i) % 7];
    }
    return totalMinutes;
  }

  public static totalMinutesToHours(totalMinutes: number, formatUnit = true): string {
    const hours = Math.floor(totalMinutes / 60) || 0;
    const minutes = Math.floor(totalMinutes % 60) || 0;
    const hoursFormat = `${hours}${formatUnit ? 'h' : ''}`;
    return minutes > 0
      ? `${hoursFormat}${formatUnit ? ' ' : ':'}${String(minutes).padStart(2, '0')}${formatUnit ? 'm' : ''}`
      : hoursFormat;
  }

  /**
   * @param hoursFormat HH(:MM)
   */
  public static hoursToTotalMinutes(hoursFormat: string): number {
    const [hours, minutes] = String(hoursFormat).split(':');
    return parseInt(hours) * 60 + (minutes ? parseInt(minutes) : 0);
  }

  public static scheduleToHours<T>(schedule: T): T {
    return Object.keys(schedule).reduce((acc, key) => {
      if (key.includes('Minutes') && schedule[key]) {
        acc[key] = ScheduleProcessor.totalMinutesToHours(schedule[key], false);
      } else {
        acc[key] = schedule[key];
      }
      return acc;
    }, {} as T);
  }

  public static getMinsPerWeek = (ws: ScheduleRange) => ws.minutesPerDayMask.reduce((acc, day) => acc + day, 0);

  public static scheduleToMinutes<T>(schedule: T): T {
    return Object.keys(schedule).reduce((acc, key) => {
      if (key.includes('Minutes')) {
        acc[key] = ScheduleProcessor.hoursToTotalMinutes(schedule[key]);
      } else {
        acc[key] = schedule[key];
      }
      return acc;
    }, {} as T);
  }

  public filterRangesByDate(ranges: (ScheduleRange | IPayCalcItemVM)[], minDate: any, maxDate: any) {
    const maxDate_ = moment(maxDate).endOf('day');
    return ranges?.filter((r) => !(minDate.isAfter(r.to) || maxDate_.isBefore(r.from)));
  }

  public getHoursInDate(date) {
    const ix = -this.rangesData.minDate.diff(date, 'day');
    if (ix >= 0 && ix < this.rangesData.leavePerDay.length) {
      return [this.rangesData.leavePerDay[ix], this.rangesData.workPerDay[ix], this.rangesData.payableOffDay[ix]];
    }
    return [0, 0, 0];
  }

  public getOverlappedRanges(
    startDate,
    endDate,
    { paysOnLeaveDays, paysOnWorkedDays, prorationMethod, payPeriodLength },
  ): { from: Date; to: Date; overlap: boolean }[] {
    const startMoment = moment(startDate).startOf('day');
    const endMoment = moment(endDate).startOf('day');
    const ranges = [];
    let currentStrike = undefined;
    while (startMoment <= endMoment) {
      const [hoursLeave, hoursWork, hoursPayableOff] = this.getHoursInDate(startMoment);
      const hasLeft = hoursLeave > 0;
      const hasWorked = hoursWork > 0 && hoursWork > hoursLeave;
      // Note: only calendar day prorated items pay on off days
      // and right now we only allow this to be selected for weekly/pay-period items
      const hasPayableOff =
        hoursPayableOff > 0 &&
        prorationMethod === EProrationMethod.CALENDAR_DAYS &&
        (payPeriodLength === EPaycalcItemLength.WEEKLY || payPeriodLength === EPaycalcItemLength.PAY_PERIOD);
      const overlap = Boolean(
        (paysOnLeaveDays && hasLeft) || (paysOnWorkedDays && hasWorked) || (paysOnLeaveDays && hasPayableOff),
      );

      if (!currentStrike) {
        // initial case
        currentStrike = {
          from: startMoment.toDate(),
          overlap,
        };
        ranges.push(currentStrike);
      }
      // switch range
      if (currentStrike.overlap !== overlap) {
        currentStrike.to = startMoment.toDate();
        currentStrike = {
          from: startMoment.toDate(),
          overlap,
        };
        ranges.push(currentStrike);
      }
      startMoment.add(1, 'day');
    }
    currentStrike.to = startMoment.toDate(); // close last item
    return ranges;
  }

  public loadLeaveRanges(ranges: ScheduleRange[], minDate: any, maxDate: any, leave: ILeaveHRVM) {
    const rangesData = {
      minDate: moment(minDate).startOf('day'),
      maxDate: moment(maxDate).startOf('day'),
      leavePerDay: [],
      workPerDay: [],
      payableOffDay: [],
    };
    this.rangesData = rangesData;

    const rangeLen = rangesData.maxDate.diff(rangesData.minDate, 'day') + 1;
    if (!rangeLen || rangeLen <= 0) {
      return;
    }
    rangesData.leavePerDay = Array(rangeLen).fill(0);
    rangesData.workPerDay = Array(rangeLen).fill(0);
    rangesData.payableOffDay = Array(rangeLen).fill(0);

    [defaultWorkSchedule, ...ranges].forEach((r) => {
      // mask mode
      const fromMoment = moment.max(moment(r.from), rangesData.minDate).hours(0);
      const toMoment = moment.min(moment(r.to), rangesData.maxDate).hours(0);
      const rangeStartIx = -rangesData.minDate.diff(fromMoment, 'day');
      const rangeEndIx = -rangesData.minDate.diff(toMoment, 'day');

      const isWorkTime = r.type === EScheduleType.WORK_REGULAR;

      const fromWeekDay = fromMoment.weekday();
      for (let i = rangeStartIx; i <= rangeEndIx; i++) {
        const hours = r.minutesPerDayMask[(fromWeekDay + i - rangeStartIx) % 7] / 60;
        // @NOTE - we might slightly lose precision here. It may be better to use minutes, and have presentation logic handle conversion to hours
        const hoursForView = Math.round(hours * 100) / 100;
        if (isWorkTime) {
          rangesData.workPerDay[i] = hoursForView; // overwrite
        } else if (hours) {
          rangesData.leavePerDay[i] += hoursForView;
          rangesData.payableOffDay[i] = 0;
        } else {
          rangesData.payableOffDay[i] = 1;
        }
      }
    });
    for (let i = 0; i < rangesData.leavePerDay.length; i++) {
      // if (rangesData.workPerDay[i] < rangesData.leavePerDay) {
      //   throw new BadRequestException('Cant register leave outside working schedule');
      // }
      if (leave?.usesIrregularSchedule && rangesData.workPerDay[i] < rangesData.leavePerDay[i]) {
        rangesData.workPerDay[i] = rangesData.leavePerDay[i];
      }
      if (rangesData.leavePerDay[i] === 0 && rangesData.workPerDay[i] === 0) {
        rangesData.leavePerDay[i] = -1; // non working day
      }

      if (rangesData.workPerDay[i] > 0) {
        rangesData.payableOffDay[i] = 0;
      }
    }
  }

  public getWeekRangeForDate(aDate) {
    return {
      from: this.getAsDateString(aDate.startOf('week').startOf('day')),
      to: this.getAsDateString(aDate.endOf('week').startOf('day')),
    };
  }

  public getAsDateString(momentDate) {
    return momentDate.format(moment.HTML5_FMT.DATE);
  }
}
