import { createStitches } from '@stitches/react';

// To use, import styled from this file. See https://stitches.dev/docs/installation#import-and-use-it
export const { styled, css, globalCss, keyframes, getCssText, theme, createTheme, config } = createStitches({
  theme: {
    colors: {
      black: '#212429',
      grey400: '#495057',
      grey300: '#6E7681',
      grey350: '#676767',
      grey250: '#D3D7DB',
      grey200: '#DEE6EB',
      grey100: '#F8F9FC',
      borderGrey: '#F3F4FA',
      white: '#FFFFFF',
      white200: '#FAFBFF',
      primaryEggplant: '#4A4177',
      primaryEggplantDark: '#3A3264',
      primaryEggplantMedium: '#A5A0BB',
      primaryEggplantLight: '#EDECF1',
      primaryPeacock: '#018E91',
      primaryPeacockDark: '#017C7E',
      primaryPeacockDarker: '#016366',
      primaryPeacockMedium: '#80C6C8',
      primaryPeacockLight: '#E6F4F4',
      primaryCoral: '#E0311A',
      logoRed: '#F15D4A',
      primaryCoralDark: '#C91703',
      primaryCoralMedium: '#F8AEA4',
      primaryCoralLight: '#FEEFED',
      secondaryPumpkin: '#FB7E21',
      secondaryPumpkinMedium: '#FED8BC',
      secondaryPumpkinLight: '#FFF2E9',
      secondaryGrass: '#38976A',
      secondaryGrassLight: '#EBF5F0',
      secondarySky: '#365C94',
      secondarySkyLight: '#EBEFF4',
      secondarySand: '#F4B96D',
      ripplingYellow: '#FDB71C',
      gradientEggplant: 'linear-gradient(90deg, #867DAF 0%, #382C71 100%)',
      gradientPeacock: 'linear-gradient(90deg, #42C6CA -3.33%, #037577 100%)',
      gradientCoral: 'linear-gradient(90deg, #FF8D7E 0%, #DB2E1C 100%)',
      gradientPumpkin: 'linear-gradient(90deg, #FF9E45 0%, #DB5D00 100%)',
      gradientGrass: 'linear-gradient(90deg, #56CE95 0%, #027C42 100%)',
      gradientSky: 'linear-gradient(90deg, #80A2D5 -3.33%, #1E4C8F 100%)',
      gradientSand: 'linear-gradient(90deg, #FFD687 0%, #DE9638 100%)',
    },
    fonts: {
      Nunito: 'Nunito',
      Poppins: 'Poppins',
    },
    fontSizes: {
      body12: '12px',
      body14: '14px',
      body16: '16px',
      body18: '18px',
      heading20: '20px',
      heading24: '24px',
      heading26: '26px',
      heading32: '32px',
      heading40: '40px',
      heading48: '48px',
    },
    radii: {
      2: '2px',
      4: '4px',
      6: '6px',
      8: '8px',
    },
    lineHeights: {
      18: '18px',
      21: '21px',
      24: '24px',
      30: '30px',
      34: '34px',
      36: '36px',
    },
    fontWeights: {
      regular400: 400,
      medium500: 500,
      semibold600: 600,
      semibold700: 700,
      extrabold800: 800,
    },
    sizes: {
      20: '20px',
      42: '42px',
    },
    borderWidths: {
      2: '2px',
    },
    space: {
      0: '0px',
      1: '1px',
      2: '2px',
      4: '4px',
      6: '6px',
      8: '8px',
      10: '10px',
      12: '12px',
      16: '16px',
      18: '18px',
      20: '20px',
      24: '24px',
      28: '28px',
      32: '32px',
      38: '38px',
      40: '40px',
      42: '42px',
      48: '48px',
      64: '64px',
    },
    shadows: {
      dp1: '0px 1px 3px 0px #0000001A,0px 2px 1px 0px #0000000D 0px 1px 1px 0px #0000000D',
      dp4: '0px 2px 4px 0px #00000033, 0px 1px 10px 0px #0000001F, 0px 4px 5px 0px #00000024',
      dp9: '0px 5px 6px 0px #00000033, 0px 3px 16px 0px #0000001F, 0px 9px 12px 0px #00000024',
      dp24: '0px 11px 15px 0px #00000033, 0px 9px 46px 0px #0000001F, 0px 24px 38px 0px #00000024',
      primaryEggplantLight: '#EDECF1',
      primaryCoral: '#E0311A',
      secondarySky: '#365C94',
      grey200: '#DEE6EB',
      primaryPeacock: '#018E91',
      primaryPeacockDark: '#017C7E',
    },
  },
  media: {
    xsm: '(min-width: 475px)',
    sm: '(min-width: 640px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 1024px)',
    xl: '(min-width: 1280px)',
    xxl: '(min-width: 1536px)',
    xsmMax: '(max-width: 474px)',
    smMax: '(max-width: 639px)',
    mdMax: '(max-width: 767px)',
    lgMax: '(max-width: 1023px)',
    xlMax: '(max-width: 1279px)',
    xxlMax: '(max-width: 1535px)',
  },
  utils: {
    hideScrollbar: () => ({
      // For WebKit browsers (Chrome, Safari)
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      // For Firefox
      scrollbarWidth: 'none',
      // For smooth scrolling on touch devices
      '-webkit-overflow-scrolling': 'touch',
    }),
  },
});
