import { Close } from '@radix-ui/react-popover';
import React from 'react';

interface IProps extends React.ComponentPropsWithoutRef<typeof Close> {
  children?: React.ReactChild | React.ReactChild[];
}

/**
 * https://www.radix-ui.com/primitives/docs/components/popover#close
 */
export const PopoverClose = ({ children, ...rest }: IProps) => {
  return (
    <Close data-testid="popover-close" {...rest}>
      {children}
    </Close>
  );
};
