import { Content } from '@radix-ui/react-popover';
import React from 'react';
import { keyframes, styled } from 'stitches.config';

interface IProps extends React.ComponentPropsWithoutRef<typeof Content> {
  children?: React.ReactChild | React.ReactChild[];
}

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const StyledContent = styled(Content, {
  minWidth: 156,
  backgroundColor: '$white',
  borderRadius: 4,
  border: '1px solid $gray300',
  padding: '$8 $1',
  height: '100%',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14)',
  animationDuration: '400ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  willChange: 'transform, opacity',
  '&[data-state="open"]': {
    '&[data-side="top"]': { animationName: slideDownAndFade },
    '&[data-side="right"]': { animationName: slideLeftAndFade },
    '&[data-side="bottom"]': { animationName: slideUpAndFade },
    '&[data-side="left"]': { animationName: slideRightAndFade },
  },
});

/**
 * https://www.radix-ui.com/primitives/docs/components/popover#content
 */
export const PopoverContent = ({ children, ...rest }: IProps) => {
  return (
    <StyledContent data-testid="popover-content" {...rest}>
      {children}
    </StyledContent>
  );
};
