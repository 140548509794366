import { Header } from '@radix-ui/react-accordion';
import React from 'react';

/**
 * https://www.radix-ui.com/docs/primitives/components/accordion#header
 */
interface IProps {
  className?: string;
  children?: React.ReactChild | React.ReactChild[];
}

export const AccordionHeader = ({ className, children }: IProps) => {
  return (
    <Header className={className} data-testid="accordion-header">
      {children}
    </Header>
  );
};
