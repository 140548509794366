import styles from '@components/v2/PhoneInput/PhoneInput.module.scss';
import cx from 'classnames';
import React from 'react';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: string;
  error?: string | null;
  inputRef?: any | null;
}

const PhoneInputWithRef = ({ id, label, error, inputRef, ...rest }: IProps, ref) => {
  return (
    <label className={styles.label} htmlFor={id}>
      {label}
      <input
        className={cx(styles.input, { [styles.error]: error })}
        ref={inputRef || ref}
        {...rest}
        value={rest.value || ''}
      />
      {error && <span className={styles.error}>{error}</span>}
    </label>
  );
};

const PhoneInput = React.forwardRef<HTMLInputElement, IProps>(PhoneInputWithRef);
export default PhoneInput;
