import * as Switch from '@radix-ui/react-switch';
import React from 'react';
import { styled } from 'stitches.config';

interface IProps {
  id?: string;
  label?: string;
  checked?: boolean;
  onCheckedChange?: (checked: boolean) => void;
  disabled?: boolean;
  required?: boolean;
  name?: string;
  value?: string;
  ariaLabel?: string;
  labelOnRight?: boolean;
  showOnOrOffLabel?: boolean;
}

const ToggleSwitch = ({
  id,
  label,
  checked,
  onCheckedChange,
  disabled = false,
  required = false,
  name,
  value,
  ariaLabel,
  labelOnRight = false,
  showOnOrOffLabel = true,
}: IProps) => (
  <Flex css={{ alignItems: 'center' }}>
    {!!label && !labelOnRight && <Label htmlFor={id}>{label}</Label>}
    <SwitchRoot
      id={id}
      onCheckedChange={onCheckedChange}
      checked={checked}
      disabled={disabled}
      required={required}
      name={name}
      value={value}
      aria-label={ariaLabel}
      className={showOnOrOffLabel ? 'showOnOrOffLabel' : ''}
    >
      <SwitchThumb />
    </SwitchRoot>
    {!!label && labelOnRight && (
      <Label htmlFor={id} className="labelOnTheRight">
        {label}
      </Label>
    )}
  </Flex>
);

const SwitchRoot = styled(Switch.Root, {
  all: 'unset',
  width: 42,
  height: 25,
  backgroundColor: '$grey300',
  borderRadius: '9999px',
  position: 'relative',
  boxShadow: '0 1px 10px $grey300',
  WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
  cursor: 'pointer',
  marginRight: 16,
  '&.showOnOrOffLabel': {
    marginRight: 32,
  },
  '&::after': {
    content: '',
    position: 'absolute',
    top: 0,
    left: 'calc(100%)',
    display: 'inline-block',
    paddingLeft: 8,
    lineHeight: 1.5,
    width: 32,
    '&.showOnOrOffLabel': {
      content: 'Off',
    },
  },
  '&:focus': {
    boxShadow: `0 0 0 1px $grey300`,
  },
  '&[data-state="checked"]': {
    backgroundColor: '$primaryPeacock',
    '&::after': {
      content: '',
      '&.showOnOrOffLabel': {
        content: 'On',
      },
    },
  },
  '&[data-disabled]': {
    cursor: 'not-allowed',
  },
  '&[aria-required="true"]::before': {
    fontSize: 10,
    color: '$primaryCoral',
    content: '* Required',
    position: 'absolute',
    top: '100%',
    left: '100%',
    display: 'inline',
    whiteSpace: 'nowrap',
    paddingLeft: 8,
    lineHeight: 1.5,
  },
});

const SwitchThumb = styled(Switch.Thumb, {
  display: 'block',
  width: 21,
  height: 21,
  backgroundColor: 'white',
  borderRadius: '9999px',
  boxShadow: '0 2px 2px $black',
  transition: 'transform 100ms',
  transform: 'translateX(2px)',
  willChange: 'transform',
  '&[data-state="checked"]': { transform: 'translateX(19px)' },
});

const Flex = styled('div', { display: 'flex' });
const Label = styled('label', {
  color: '$black',
  fontSize: '$body16',
  paddingRight: 15,
  '&.labelOnTheRight': {
    marginLeft: '$8',
    paddingRight: 0,
  },
});

export default ToggleSwitch;
