import FeatherIcon from '@components/FeatherIcon';
import type * as Stitches from '@stitches/react';
import React from 'react';
import { styled } from 'stitches.config';
const Container = styled('div', {
  '& .inline': {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    '& label': {
      paddingBlockEnd: 0,
    },
  },
});

const Label = styled('label', {
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  fontSize: '$body16',
  fontWeight: '$regular400',
  paddingBlockEnd: 8,
  variants: {
    inline: {
      true: {
        alignItems: 'center',
        paddingBlockEnd: 0,
      },
    },
  },
});

const StyledInput = styled('input', {
  fontSize: '$body16',
  background: '$white',
  padding: '12px 12px 9px',
  minWidth: 400,
  height: 45,
  borderRadius: 4,
  border: '1px solid $grey200',
  '&:focus': {
    borderColor: '$primaryPeacock',
  },
  '&:disabled': {
    backgroundColor: '$grey100',
  },
  '&::placeholder': {
    color: '$grey250',
  },
  variants: {
    error: {
      true: {
        borderColor: '$primaryCoral',
        outline: 'none',
        color: '$grey300',
        '&:focus': {
          borderColor: '$primaryCoral',
        },
      },
    },
  },
  '&::-webkit-calendar-picker-indicator': {
    filter: 'invert(39%) sepia(35%) saturate(2379%) hue-rotate(148deg) brightness(91%) contrast(99%)',
    stroke: '$primaryPeacock',
    cursor: 'pointer',
  },
  '@lg': {
    minWidth: 400,
  },
  '@lgMax': {
    width: '100%',
  },
});

const Error = styled('span', {
  display: 'block',
  fontSize: '$body12',
  color: '$primaryCoral',
  paddingInlineStart: 8,
});

export enum EInputType {
  BUTTON = 'button',
  CHECKBOX = 'checkbox',
  COLOR = 'color',
  DATE = 'date',
  DATE_TIME_LOCAL = 'datetime-local',
  EMAIL = 'email',
  FILE = 'file',
  HIDDEN = 'hidden',
  IMAGE = 'image',
  MONTH = 'month',
  NUMBER = 'number',
  PASSWORD = 'password',
  RADIO = 'radio',
  RANGE = 'range',
  RESET = 'reset',
  SEARCH = 'search',
  SELECT = 'select',
  SUBMIT = 'submit',
  TEL = 'tel',
  TEXT = 'text',
  TIME = 'time',
  URL = 'url',
  WEEK = 'week',
}

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: string;
  error?: string | Record<string, unknown>;
  css?: Stitches.CSS;
  onChange?: (event) => void;
  onBlur?: (event) => void;
  value?: any;
  onClick?: (event) => void;
  onKeyDown?: (event) => void;
  defaultValue?: any;
  name?: string;
  step?: number;
  placeholder?: string;
  type?: EInputType;
  disabled?: boolean | false;
  autoComplete?: string;
  inline?: boolean;
  role?: string;
  inputCSS?: Stitches.CSS;
}
const InputWithRef = (
  {
    id,
    label,
    error,
    css,
    placeholder,
    name,
    onChange,
    onBlur,
    disabled,
    autoComplete = 'off',
    inline,
    type = EInputType.TEXT,
    inputCSS,
    ...rest
  }: IProps,
  ref,
) => {
  return (
    <Container css={css}>
      {label ? (
        <>
          {!inline ? (
            <>
              <Label htmlFor={id}>{label}</Label>
              <StyledInput
                placeholder={placeholder}
                aria-label={label}
                type={type}
                onChange={onChange}
                onBlur={onBlur}
                id={id}
                error={!!error}
                disabled={disabled}
                autoComplete={autoComplete}
                {...rest}
                value={rest.value || ''}
                ref={ref}
                css={inputCSS}
              />
            </>
          ) : (
            <div className="inline">
              <StyledInput
                placeholder={placeholder}
                aria-label={label}
                type={type}
                onChange={onChange}
                id={id}
                onBlur={onBlur}
                error={!!error}
                disabled={disabled}
                autoComplete={autoComplete}
                {...rest}
                ref={ref}
                css={inputCSS}
              />
              <Label htmlFor={id}>{label}</Label>
            </div>
          )}
          {error && <Error role="alert">{String(error)}</Error>}
        </>
      ) : (
        <>
          <StyledInput
            aria-label={name}
            placeholder={placeholder}
            error={!!error}
            type={type}
            id={id}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            name={name}
            autoComplete={autoComplete}
            ref={ref}
            css={inputCSS}
            {...rest}
          />
          {error && <Error role="alert">{String(error)}</Error>}
        </>
      )}
    </Container>
  );
};

const Input = React.forwardRef<HTMLInputElement, IProps>(InputWithRef);
export default Input;

interface IPropsSearchInput extends IProps {
  clear?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  iconTitle: string;
}

const IconContainer = styled('div', {
  position: 'relative',
  height: '45px',
  '& span': {
    position: 'absolute',
    right: '5%',
    top: '30%',
    '& svg': {
      width: 20,
      height: 20,
      stroke: '$primaryPeacock',
    },
  },
  '& button': {
    position: 'absolute',
    outline: 'none',
    border: 'none',
    lineHeight: 0,
    cursor: 'pointer',
    padding: 0,
    background: 'transparent',
    right: '5%',
    top: '30%',
    '& svg': {
      width: 20,
      height: 20,
      stroke: '$primaryPeacock',
    },
  },
});
/**
 * Currently this input is not set up to use a label, if you need to add that you will need
 * to either reorder this component or update the CSS to accommodate
 */
export const SearchInput = ({ clear, iconTitle, ...props }: IPropsSearchInput) => {
  return (
    <IconContainer>
      <Input {...props} aria-label="Search" />
      {clear && props.value ? (
        <button title="Clear the input" onClick={clear} type="button">
          <FeatherIcon name="X" />
        </button>
      ) : (
        <span title={iconTitle}>
          <FeatherIcon name="Search" />
        </span>
      )}
    </IconContainer>
  );
};
