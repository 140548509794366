import type * as Stitches from '@stitches/react';
import React, { ReactNode } from 'react';
import { styled } from 'stitches.config';

export enum BadgeColor {
  PRIMARY = 'primary',
  PRIMARY_DARK = 'primaryDark',
  GRASS = 'grass',
  SOLID_GREEN = 'solidGreen',
  CORAL = 'coral',
  SKY = 'sky',
  EGGPLANT = 'eggplant',
  SOLID_EGGPLANT = 'solidEggplant',
  PUMPKIN = 'pumpkin',
  SOLID_PRIMARY = 'solidPrimary',
  SOLID_GREY = 'solidGrey',
  SOLID_PUMPKIN = 'solidPumpkin',
}

interface BadgePropTypes {
  children: ReactNode | ReactNode[];
  color?: BadgeColor;
  className?: string;
  onClick?: () => void;
  css?: Stitches.CSS;
  dataTestId?: string;
}

const StyledBadge = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  borderRadius: 200,
  textTransform: 'uppercase',
  height: 21,
  width: 'fit-content',
  maxWidth: 150,
  fontSize: '0.625rem',
  padding: '4px 12px 3px',
  fontWeight: '$semibold700',
  whiteSpace: 'nowrap',
  variants: {
    color: {
      primary: {
        color: '$primaryPeacock',
        backgroundColor: '$primaryPeacockLight',
        border: '1px solid $primaryPeacock',
      },
      primaryDark: {
        color: '$primaryPeacockDark',
        backgroundColor: '$primaryPeacockLight',
        border: '1px solid $primaryPeacockDark',
      },
      grass: {
        color: '$secondaryGrass',
        backgroundColor: '$secondaryGrassLight',
        border: '1px solid $secondaryGrass',
      },
      solidGreen: {
        color: '$white',
        backgroundColor: '$secondaryGrass',
      },
      coral: {
        color: '$primaryCoral',
        backgroundColor: '$primaryCoralLight',
        border: '1px solid $primaryCoral',
      },
      sky: {
        color: '$secondarySky',
        backgroundColor: '$secondarySkyLight',
        border: '1px solid $secondarySky',
      },
      eggplant: {
        color: '$primaryEggplant',
        backgroundColor: '$primaryEggplantLight',
        border: '1px solid $primaryEggplant',
      },
      solidEggplant: {
        color: '$white',
        backgroundColor: '$primaryEggplant',
      },
      pumpkin: {
        color: '$secondaryPumpkin',
        backgroundColor: '$secondaryPumpkinLight',
        border: '1px solid $secondaryPumpkin',
      },
      solidPrimary: {
        color: '$white',
        backgroundColor: '$primaryPeacock',
      },
      solidGrey: {
        color: '$white',
        backgroundColor: '$grey250',
      },
      solidPumpkin: {
        color: '$white',
        backgroundColor: '$secondaryPumpkin',
      },
    },
  },
});

const Badge = ({
  children,
  className,
  color = BadgeColor.PRIMARY,
  onClick,
  css,
  dataTestId = 'badge',
}: BadgePropTypes) => {
  return (
    <StyledBadge css={css} data-testid={dataTestId} className={className} color={color} onClick={onClick}>
      {children}
    </StyledBadge>
  );
};

export default Badge;
