import React from 'react';

import { PaginationIcon, PaginationIconWrapper, PaginationSection, PaginationTypography } from './components';

interface IProps {
  onPreviousPage: () => void;
  onNextPage: () => void;
  currentPage: number;
  totalPages: number;
  setToFirstPage: () => void;
  setToLastPage: () => void;
}
export const PaginationAndFileSelect = ({
  onPreviousPage,
  onNextPage,
  currentPage,
  totalPages,
  setToFirstPage,
  setToLastPage,
}: IProps) => {
  return (
    <PaginationSection>
      <PaginationIconWrapper>
        <PaginationIcon
          name="ChevronsLeft"
          onIconClick={setToFirstPage}
          title="Go to First Page"
          aria-label="Go to First Page"
        />
        <PaginationIcon
          name="ChevronLeft"
          onIconClick={onPreviousPage}
          title="Previous Page"
          role="button"
          aria-label="Previous Page"
        />
        <PaginationTypography>{`Page ${currentPage} of ${totalPages}`}</PaginationTypography>
        <PaginationIcon
          name="ChevronRight"
          onIconClick={onNextPage}
          title="Next Page"
          role="button"
          aria-label="Next Page"
        />
        <PaginationIcon
          name="ChevronsRight"
          onIconClick={setToLastPage}
          title="Go to Last Page"
          aria-label="Go to Last Page"
        />
      </PaginationIconWrapper>
    </PaginationSection>
  );
};
