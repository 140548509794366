import { Root } from '@radix-ui/react-accordion';
import React from 'react';

/**
 * https://www.radix-ui.com/docs/primitives/components/accordion#root
 */

export enum AccordionType {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}

interface IProps {
  className?: string;
  type?: AccordionType;
  defaultValue?: string[] | string;
  value?: string[] | string;
  onValueChange?: (value: string[] | string) => void;
  children?: React.ReactNode;
}

export const AccordionRoot = ({
  className,
  children,
  defaultValue,
  value,
  type = AccordionType.SINGLE,
  onValueChange,
}: IProps) => {
  // Radix set up the Accordion types to be distinct so this is a workaround for that. Check out their types file to understand
  if (type === AccordionType.SINGLE) {
    return (
      <Root
        collapsible
        type={AccordionType.SINGLE}
        defaultValue={defaultValue as string}
        value={value as string}
        onValueChange={onValueChange as (value: string) => void}
        className={className}
        data-testid="accordion-root"
      >
        {children}
      </Root>
    );
  }
  return (
    <Root
      type={AccordionType.MULTIPLE}
      defaultValue={defaultValue as string[]}
      value={value as string[]}
      onValueChange={onValueChange as (value: string[]) => void}
      className={className}
      data-testid="accordion-root"
    >
      {children}
    </Root>
  );
};
