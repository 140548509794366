import { IUserVM } from '@types';
import React, { createContext, useContext, useReducer } from 'react';

export const SET_ME = 'SET_ME';

type Dispatch = (action) => void;
type State = { me: IUserVM };
type AuthProviderProps = { children: React.ReactNode };

export const AuthContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

export function authReducer(state: State, action) {
  switch (action.type) {
    case SET_ME: {
      const payload = action.payload && { ...action.payload, ...action.payload.user };
      payload && delete payload['user'];
      return {
        ...state,
        me: payload || null,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(authReducer, { me: null });
  const value = { state, dispatch };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useIdentity() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useIdentity must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useIdentity };
