const routes = {
  ADMIN: {
    TEMPLATES: '/admin/templates',
    PLAN_DETAILS: '/plan',
  },
  AUTH: {
    RESET_PASSWORD: '/reset-password',
    FORGOT_PASSWORD: '/forgot-password',
    ACTIVATE: '/activate',
    LOGGED_OUT: '/logged-out',
    LOGIN: '/login',
    RESET: '/reset',
  },
  EMPLOYEE: {
    NEW_LEAVE: '/leave/new',
    EDIT_LEAVE: '/leave/edit',
    VIEW_PLAN: '/plan',
  },
  HR: {
    NEW_LEAVE: '/hr/newleave',
    DASHBOARD: '/hr/dashboard',
    REVIEW_LEAVE: '/hr/reviewleave',
    EDIT_LEAVE: '/hr/editleave',
    REDIRECT_LEAVE: '/hr/deepLinkLeave',
    PAYROLL_DASHBOARD: '/hr/payroll',
    VIEW_PLAN: 'plans/plan',
    PLAN_DETAILS_PAGE: '/hr/plans/plan',
    PAYCALC_VERSION_HISTORY: '/hr/leaves/paycalcs',
  },
};

export { routes };
