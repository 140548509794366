import * as ReactTooltip from '@radix-ui/react-tooltip';
import { useState } from 'react';
import { styled } from 'stitches.config';

import Tooltip from './Tooltip';

const StyledTooltipButton = styled('button', {
  padding: 2,
});

interface IProps extends React.ComponentPropsWithoutRef<typeof ReactTooltip.Tooltip> {
  testid?: string;
  content: string | React.ReactChild | React.ReactChild[];
  avoidCollisions?: boolean;
}

function ClickableTooltip({ testid = 'clickable-tooltip', content, avoidCollisions = false, children }: IProps) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleTooltipOpen = (e) => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Tooltip content={content} open={isOpen} onOpenChange={toggleTooltipOpen} avoidCollisions={avoidCollisions}>
      <StyledTooltipButton onClick={toggleTooltipOpen} data-testid={testid} type="button">
        {children}
      </StyledTooltipButton>
    </Tooltip>
  );
}

export default ClickableTooltip;
