import { BadgeColor } from '@components/v2/Badge/Badge';
import { ELeaveStatus } from '@types';

import FILE from '@public/FILE.svg';
import CSV from '@public/CSV.svg';
import PDF from '@public/PDF.svg';
import DOC from '@public/DOC.svg';
import PNG from '@public/PNG.svg';
import JPG from '@public/JPG.svg';
import TXT from '@public/TXT.svg';
import XLS from '@public/XLS.svg';
import ZIP from '@public/ZIP.svg';

export const leaveStatusMap = {
  [ELeaveStatus.DRAFT]: { value: 'Draft', color: BadgeColor.EGGPLANT },
  [ELeaveStatus.SENT_TO_EMPLOYEE]: { value: 'Waiting on Employee', color: BadgeColor.CORAL },
  [ELeaveStatus.SENT_TO_HR]: { value: 'Waiting on HR', color: BadgeColor.CORAL },
  [ELeaveStatus.APPROVED_EMPLOYEE]: { value: 'Under Review', color: BadgeColor.EGGPLANT },
  [ELeaveStatus.APPROVED_HR]: { value: 'Active', color: BadgeColor.EGGPLANT },
  [ELeaveStatus.REJECTED_HR]: { value: 'Rejected by HR', color: BadgeColor.CORAL },
};

export const paidPerChoices = {
  ANNUALLY: 'Year',
  MONTHLY: 'Month',
  WEEKLY: 'Week',
  HOURLY: 'Hour',
};

export const leaveFrequency = {
  CONTINUOUS: 'CONTINUOUS',
  INTERMITTENT: 'INTERMITTENT',
};

export const MIME_ICON_MAP = Object.freeze({
  'text/csv': CSV,
  'application/pdf': PDF,
  'application/msword': DOC,
  'image/png': PNG,
  'image/jpg': JPG,
  'image/jpeg': JPG,
  'text/plain': TXT,
  'application/vnd.ms-excel': XLS,
  'application/zip': ZIP,
  'image/svg+xml': FILE,
  other: FILE,
});

export const EXTENSION_ICON_MAP = Object.freeze({
  '.csv': 'text/csv',
  '.pdf': 'application/pdf',
  '.doc': 'application/msword',
  '.png': 'image/png',
  '.jpeg': 'image/jpeg',
  '.jpg': 'image/jpg',
  '.txt': 'text/plain',
  '.xsl': 'application/vnd.ms-excel',
  '.zip': 'application/zip',
  other: 'other',
});

export const SCHEDULE_DAY_STATUSES = Object.freeze({
  APPROVED: 'APPROVED',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  DENIED: 'DENIED',
  PENDING_DELETION: 'PENDING_DELETION',
});

export const SCHEDULE_DAY_TYPES = Object.freeze({
  LEAVE_REGULAR: 'LEAVE_REGULAR',
  WORK_REGULAR: 'WORK_REGULAR',
  BENEFIT: 'BENEFIT',
});

export const HELP_PAGE = 'https://help.hellotilt.com';
export const LOGIN_SUPPORT_PAGE = 'https://help.hellotilt.com/en_us/troubleshoot-your-user-login-H1LZAQVEs';
export const KUSTOMER_CONTACT_FORM = 'https://tilt.kustomer.help/contact/leave-support-SyCQ6MD6q';
export const SSO_SETUP_INSTRUCTIONS_URL = 'https://tilt.kustomer.help/en_us/categories/saml-sso-r1wPl5Z5p';
export const DOWNTIME_LINK = 'https://help.hellotilt.com/en_us/notice-platform-downtime-on-february-3rd-BJTPvCCij';

export const RESPONSIVE_BREAKPOINTS = Object.freeze({
  EXTRA_SMALL: 475,
  SMALL: 640,
  MEDIUM: 768,
  LARGE: 1024,
  EXTRA_LARGE: 1280,
  EXTRA_EXTRA_LARGE: 1536,
});

export const RIPPLING_PAYCALC_TEMPLATE_TITLES = [
  'Rippling Personal Time',
  'Rippling Vacation Time',
  'Rippling Sick Leave',
];

export const BALANCE_TYPES = Object.freeze({
  SICK: 'SICK',
  PTO: 'PTO',
  PERSONAL_DAYS: 'PERSONAL_DAYS',
  VACATION: 'VACATION',
});

export const KUSTOMER_PAYROLL_REPORT_HELP_LINK = 'https://tilt.kustomer.help/en_us/payroll-report-overview-SkEPPtK4i';

export const SERVICE_USER_EMAIL = 'tilt-service-user-account@ourtilt.com';

export const dateStringFormat = 'MM/d/yyyy';
export const timeStringFormat = 'hh:mm a';
