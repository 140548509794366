import actionKey from '@utils/action-key';
import request from '@utils/request';
import PromiseCreator from 'lib/promise-creator';
import { combineReducers } from 'redux';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { closePromptAction, openPromptAction, showPromptErrorAction } from 'store/prompt';

import {
  ADD_EMPLOYEE,
  CANCEL_DEACTIVATE_EMPLOYEE,
  CANCEL_DELETE_EMPLOYEE,
  CANCEL_RESET_EMPLOYEE_MFA,
  DEACTIVATE_EMPLOYEE,
  DELETE_EMPLOYEE,
  EDIT_EMPLOYEE,
  RESET_EMPLOYEE_MFA,
} from './constants';

const noop = () => null;

// API Endpoints add, edit, deactivate, delete moved to APIV3 ENG-1383
export const addEmployeeAction = (payload, { resolve = noop, reject = noop } = {}) => ({
  type: ADD_EMPLOYEE,
  payload,
  resolve,
  reject,
});

// eslint-disable-next-line max-len
export const editEmployeeAction = (id, payload, { resolve = noop, reject = noop } = {}) => ({
  type: EDIT_EMPLOYEE,
  id,
  payload,
  resolve,
  reject,
});

export const resetEmployeeMfaAction = (id, payload, { resolve = noop, reject = noop } = {}) => ({
  type: RESET_EMPLOYEE_MFA,
  id,
  payload,
  resolve,
  reject,
});

export const deleteEmployeeAction = (payload, { resolve = noop, reject = noop } = {}) => ({
  type: DELETE_EMPLOYEE,
  payload,
  resolve,
  reject,
});

export const cancelResetMfaAction = () => ({
  type: CANCEL_RESET_EMPLOYEE_MFA,
  payload: {},
});

export const cancelDeleteEmployeeAction = () => ({
  type: CANCEL_DELETE_EMPLOYEE,
  payload: {},
});

export const deactivateEmployeeAction = (payload, { resolve = noop, reject = noop } = {}) => ({
  type: DEACTIVATE_EMPLOYEE,
  payload,
  resolve,
  reject,
});

export const cancelDeactivateEmployeeAction = () => ({
  type: CANCEL_DEACTIVATE_EMPLOYEE,
  payload: {},
});

const usersById = (state = {}, action) => {
  switch (action.type) {
    case actionKey.success(ADD_EMPLOYEE):
    case actionKey.success(EDIT_EMPLOYEE):
    case actionKey.success(DEACTIVATE_EMPLOYEE):
      return {
        ...state,
        [action.payload.id]: {
          ...(state[action.payload.id] || {}),
          ...action.payload,
        },
      };
    case actionKey.success(DELETE_EMPLOYEE):
      if (state[action.id]) {
        delete state[action.id];
      }

      return {
        ...state,
      };
    default:
      return state;
  }
};

export default combineReducers({
  byId: usersById,
});

export function* rootSaga() {
  yield all([
    takeEvery(EDIT_EMPLOYEE, editEmployee),
    takeEvery(ADD_EMPLOYEE, addEmployee),
    takeEvery(RESET_EMPLOYEE_MFA, resetEmployeeMfa),
    takeEvery(DELETE_EMPLOYEE, deleteEmployee),
    takeEvery(DEACTIVATE_EMPLOYEE, deactivateEmployee),
  ]);
}

export function* fetchAllSuperusers({ type, resolve, reject }) {
  yield call(request, {
    type,
    url: '/v2/admin/users/superusers',
    resolve,
    reject,
  });
}

export function* addEmployee({ type, payload, resolve, reject }) {
  yield call(request, {
    type,
    url: '/api/admin/users/',
    options: {
      method: 'POST',
      body: payload,
      apiV3: true,
    },
    resolve,
    reject,
    companyId: payload.companyId,
  });
}

export function* editEmployee({ type, id, payload, resolve, reject }) {
  yield call(request, {
    type,
    url: `/api/admin/users/${id}/`,
    options: {
      method: 'PUT',
      body: payload,
      apiV3: true,
    },
    resolve,
    reject,
  });
}

export function* deleteEmployee({ type, payload, resolve, reject }) {
  const prompt = PromiseCreator();
  const { id: userId, companyId } = payload;
  yield put(
    openPromptAction({
      resolve: prompt.resolve,
      title: 'Delete Employee',
      message: 'Are you sure that you want to Delete this employee?',
      okButton: 'Delete',
      showLoading: true,
    }),
  );

  const upgrade = yield prompt.promise;

  if (upgrade) {
    const result = yield call(request, {
      type,
      url: `/api/admin/users/${userId}/`,
      options: {
        method: 'DELETE',
        apiV3: true,
      },
      resolve,
      reject,
      userId,
      companyId,
    });

    if (result.error) {
      yield put(showPromptErrorAction(result.error));
      yield deleteEmployee({
        type,
        payload,
        resolve,
        reject,
      });
    }
  } else {
    yield put(cancelDeleteEmployeeAction());
  }
  yield put(closePromptAction());
}

export function* deactivateEmployee({ type, payload, resolve, reject }) {
  const prompt = PromiseCreator();
  const { id: userId, companyId, isArchived } = payload;
  const [actionText] = isArchived ? ['Deactivate'] : ['Activate'];
  yield put(
    openPromptAction({
      resolve: prompt.resolve,
      title: `${actionText} Employee`,
      message: `Are you sure that you want to ${actionText} this employee?`,
      okButton: actionText,
      showLoading: true,
    }),
  );

  const upgrade = yield prompt.promise;

  if (upgrade) {
    const result = yield call(request, {
      type,
      url: `/api/admin/users/${userId}/`,
      options: {
        method: 'PATCH',
        body: { isArchived },
        apiV3: true,
      },
      resolve,
      reject,
      userId,
      companyId,
    });

    if (result.error) {
      yield put(showPromptErrorAction(result.error));
      yield deactivateEmployee({
        type,
        payload,
        resolve,
        reject,
      });
    }
  } else {
    yield put(cancelDeactivateEmployeeAction());
  }
  yield put(closePromptAction());
}

export function* resetEmployeeMfa({ type, id, payload, resolve, reject }) {
  const prompt = PromiseCreator();

  yield put(
    openPromptAction({
      resolve: prompt.resolve,
      title: 'Reset 2FA',
      message: `Are you sure that you want to reset multi-factor for ${payload.name}?`,
      okButton: 'Reset',
      showLoading: true,
    }),
  );

  const upgrade = yield prompt.promise;

  if (upgrade) {
    const result = yield call(request, {
      type,
      url: `/api/auth/admin/user/${id}/2fa-reset/`,
      options: {
        method: 'POST',
        apiV3: true,
      },
      resolve,
      reject,
      companyId: payload.companyId,
    });

    if (result.error) {
      yield put(showPromptErrorAction(result.error));
      yield resetEmployeeMfa({
        id,
        type,
        payload,
        resolve,
        reject,
      });
    }
  } else {
    yield put(cancelResetMfaAction());
  }

  yield put(closePromptAction());
}
