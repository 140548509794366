import { Portal } from '@radix-ui/react-popover';
import React from 'react';

interface IProps extends React.ComponentPropsWithoutRef<typeof Portal> {
  children?: React.ReactChild | React.ReactChild[];
}

/**
 * https://www.radix-ui.com/docs/primitives/components/popover#portal
 */
export const PopoverPortal = ({ children, ...rest }: IProps) => {
  return <Portal {...rest}>{children}</Portal>;
};
