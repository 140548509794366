import { useEffect, useMemo, useCallback } from 'react';
import jwt_decode from 'jwt-decode';
import useApi from '@api/transportLayer';
import JWT from 'jwt-simple';

import { getAccessToken } from '@utils/auth-tokens';
import { useIdentity } from 'contexts/auth-context';
import { formatDate } from '@utils/date-format';
import { runWithErrorLogging } from '@utils/errorLogging';
import { EHrIntegrationType } from '@types';

declare global {
  interface Window {
    pendo: any;
  }
}

const PendoInitializer = () => {
  const {
    state: { me },
  } = useIdentity();

  if (me == undefined) {
    return null;
  }
  const environment = process.env.NEXT_PUBLIC_ENV_NAME;

  const { data: userData } = useApi.UserAggregatedData.getUserAggregatedData(
    {
      userId: me.id,
    },
    {
      enabled: !!me,
    },
  );

  const getRandomAlphanumericString = useCallback((length = 18) => {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }, []);

  const initializePendo = useMemo(() => {
    return () => {
      let visitorId;
      let superuser = me.superuser;

      if (me.impersonating) {
        const accessToken = getAccessToken();
        const { impersonator } = jwt_decode(accessToken) as any;
        visitorId = `${environment}_${impersonator}`;
        superuser = true;
      } else {
        visitorId = `${environment}_${me.id}`;
      }

      const { apiKey, domain } = me.company?.hrIntegrationConfig ?? {};
      const hasBambooIntegration =
        me.company?.hrIntegrationType === EHrIntegrationType.Bamboohr && !!(apiKey && domain);

      const payload = {
        visitor: {
          id: visitorId,
          superuser,
          isHr: me.isHr,
          isPayroll: me.isPayroll,
          impersonating: me.impersonating,
          userCreateDate: formatDate(me.createdAt),
          assignedLsm: userData?.assignedLsm,
          workingState: userData?.workingState,
          homeState: userData.homeState,
          homeCountry: userData.homeCountry,
          leave_count: userData.leaveCount,
          managed_leave_count: userData.managedLeaveCount,
        },
        account: {
          id: `${environment}_${me.superuser ? 'superuser' : me.company?.id}`,
          created_at: me.company?.createdAt ? formatDate(me.company.createdAt) : null,
          google_sso_enforced: me.company?.enforceSingleSignOn ?? null,
          saml_sso_enabled: me.company?.oktaSsoEnabled ?? null,
          two_factor_enforced: me.company?.twoFactorEnabled ?? null,
          two_factor_enabled: me.company?.twoFactorEnabled ? true : !me.ignore2faSetup,
          user_count: me.company?.userCount ?? null,
          sftp_enabled: me.company?.isSftpEnabled ?? null,
          rippling_enabled: me.company?.isRipplingIntegrated ?? null,
          bamboo_hr_enabled: hasBambooIntegration ?? null,
          workday_enabled: me.company?.isWorkdayIntegrated ?? null,
          leave_count: me.company?.leaveCount ?? null,
          active_leave_count: me.company?.activeLeaveCount ?? null,
          employee_count: me.company?.employeeCount ?? null,
          us_employee_count: me.company?.employeesInUsa ?? null,
          assigned_lsms: me.company?.supportEmail ?? null,
          is_demo: me.company?.isDemo ?? null,
          enforce_fmla_50_and_75_rule: me.company?.enforceFmla50And75Rule ?? null,
          self_funded: me.company?.settings?.selfFunded ?? null,
          ct_fmla_exempt: me.company?.settings?.ctFmlaExempt ?? null,
          disable_ee_wages_bke: me.company?.settings?.disableEeWagesBke ?? null,
          or_employer_met_headcount: me.company?.settings?.orEmployerMetHeadcount ?? null,
        },
        nonce: getRandomAlphanumericString(),
      };
      const jwt = JWT.encode(payload, process.env.NEXT_PUBLIC_PENDO_JWT_SECRET_KEY);
      (window as any).pendo.initialize({ jwt, signingKeyName: process.env.NEXT_PUBLIC_PENDO_SIGNING_KEY });
    };
  }, [me, userData, environment, getRandomAlphanumericString]);

  useEffect(() => {
    // Do not load the Pendo script if the public key is not available
    if (process.env.NEXT_PUBLIC_PENDO_API_KEY) {
      (function (p, e, n, d, o) {
        let v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = o._q || [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (w = 0, x = v.length; w < x; ++w)
          (function (m) {
            o[m] =
              o[m] ||
              function () {
                o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
              };
          })(v[w]);
        y = e.createElement(n);
        y.async = !0;
        y.src = `${process.env.NEXT_PUBLIC_API_PROTOCOL}://cdn.pendo.io/agent/static/${process.env.NEXT_PUBLIC_PENDO_API_KEY}/pendo.js`;
        z = e.getElementsByTagName(n)[0];

        z?.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');
    }

    if ((window as any).pendo && userData) {
      runWithErrorLogging(() => {
        initializePendo();
      }, 'PendoInitializer');
    }
  }, [userData, initializePendo]);

  return null;
};

export default PendoInitializer;
