import * as Stitches from '@stitches/react';
import React, { useRef } from 'react';
import { styled } from 'stitches.config';

interface IProps {
  children: React.ReactNode;
  onClick?: () => void;
  css?: Stitches.CSS;
  as?: keyof JSX.IntrinsicElements; // Add this line
}

const Wrapper = styled('div', {
  listStyleType: 'none',
  '&:focus, &:focus-visible': {
    outline: '2px solid $primaryPeacockDark',
  },
});

/**
 * This ExpandedClickableArea component is a wrapper for card or row-style components that are clickable and have an interactive element
 * as a child, such as an <a>, <input>, or <button>, with the intention that clicking any area of the wrapper will trigger
 * navigation or some other action.
 *
 * @param children - The content of the card. There must be a clickable element within the card's children that has the attribute
 * 'data-expand-click-area' to map the click action to the surface area of the card. The clickable element need not be the direct child. See the stories for examples
 * @param onClick - An optional callback that is called when the user clicks on the card. This could be used for an onClick function
 * that uses the Router, fires an event tracker, or performs some other necessary action.
 * @param css - Optional Stitches CSS properties.
 * for further reading: https://css-tricks.com/block-links-the-search-for-a-perfect-solution/
 */
const ExpandedClickableArea = ({ children, onClick = undefined, css, as = undefined }: IProps) => {
  const clickableElemTypes = ['a', 'button', 'input'];
  const refExpandedArea = useRef() as React.MutableRefObject<HTMLDivElement>;

  const handleClick = (e: React.SyntheticEvent) => {
    const clickableElem = refExpandedArea.current.querySelector('[data-expand-click-area]') as HTMLElement;
    let noTextSelected = undefined;
    if (typeof window !== 'undefined') {
      noTextSelected = !window.getSelection().toString();
    }
    if (!clickableElem) {
      throw new Error('Expected one clickable element but found none');
    }

    const target = e.target as HTMLElement;
    const targetIsClickable = clickableElemTypes.includes(target.tagName.toLowerCase());

    if (clickableElem !== e.target && !targetIsClickable && noTextSelected !== false) {
      onClick !== undefined ? onClick() : clickableElem.click();
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleClick(event);
    }
  };
  return (
    <Wrapper
      ref={refExpandedArea}
      tabIndex={0}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      data-testid="a11y-clickable-wrapper"
      css={css}
      as={as || 'div'}
    >
      {children}
    </Wrapper>
  );
};

export default ExpandedClickableArea;
