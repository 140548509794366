/* eslint-disable no-console */
import { captureException as sentryCaptureException, captureMessage as sentryCaptureMessage } from '@sentry/nextjs';
import { Exception } from '@sentry/nextjs';
import { CaptureContext } from '@sentry/types';

export const captureException = (exception: Exception | Error, context?: CaptureContext) => {
  if (process.env.NEXT_PUBLIC_CLIENT_SENTRY_DSN) {
    sentryCaptureException(exception, context);
  } else {
    console.error(exception);
  }
};

export const captureMessage = (error: string, context?: CaptureContext) => {
  if (process.env.NEXT_PUBLIC_CLIENT_SENTRY_DSN) {
    sentryCaptureMessage(error, context);
  } else {
    console.error(error);
  }
};
